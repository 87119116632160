import React from 'react';
import TermsContainer from '../containers/TermPage/TermsContainer';

class TermPage extends React.Component {
  render() {
    return (
      <div className="TermPage">
        <TermsContainer />
      </div>
    );
  }
}

export default TermPage;