import React, { Component } from 'react'
import { convertStringToUrl } from '../../utils/common'

class More extends Component {
    render() {
        let moreList
        if (this.props.dataTourMore) {
            moreList = this.props.dataTourMore.map((item, key) => {
                return (
                    <div key={key} className="col-md-6 col-6-info-tour">
                        <a href={"/tour-info/" + convertStringToUrl(item.name) + "-" + item._id}>
                            <div className="wrap-tour">
                                <img className="img-tour" src={item.cover_img.src} alt={item.cover_img.alt} />
                                <div className="tour-item-bg"></div>
                                <div className="wrap-info-tour">
                                    <div className="row">
                                        <div className=" col-9">
                                            <div className="number-day">{item.duration} ngày</div>
                                            <div className="name-tour-relevant">{item.name}</div>
                                        </div>
                                        <div className="col-3">
                                            <i className="detail-tour">→</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                )
            })
        }
        return (
            <div className="wrap-more-tour">
                <div className="row">
                    {moreList}
                </div>
            </div>
        );
    }
}

export default More;