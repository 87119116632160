import {domain} from '../../constant'
export default function getTermAPI() {
    return new Promise((resolve, reject) => {
      let url = domain + "get-terms"
      fetch(url, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    