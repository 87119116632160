import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { convertStringToUrl } from '../../utils/common'
library.add(faChevronLeft, faChevronRight)
class ListEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
        }
    }

    addView() {
        this.props.getList({ page: this.props.activePage + 1 })
        this.setState({ page: this.props.activePage + 1 })
    }

    render() {
        let event = this.props.dataListEvent
        let listEvent
        let totalPage
        if (this.props.totalPage) {
            totalPage = this.props.totalPage
        }
        if (event) {
            listEvent = event.map((item, key) => {
                // Find first content
                let smallDesc = ""
                const pArr = new DOMParser().parseFromString(item.description, 'text/html').getElementsByTagName("p");
                for(let i = 0; i < pArr.length; i++)
                    smallDesc += pArr[i].textContent ? pArr[i].textContent + " " : ""
                // const descRgx = item.description.match(/(?<=>)[ A-Za-z0-9][^>]+(?=<)/g)
                const dt = new Date(item.time)
                const date = dt.getDate().toString().padStart(2, '0')
                const month = dt.getMonth() + 1
                const year = dt.getFullYear()

                return (
                    <div key={key} className="col-lg-3 col-md-6 col-sm-12 col-3-event">
                        <a className="reset-a-parent" href={"/event-info/" + convertStringToUrl(item.name) + "-" + item._id} >
                            <div className="wrap-event-new event-box-new">
                                <div className="event-item-img-date">
                                    <div className="event-date"> {date}</div>
                                    <div className="event-month">tháng {month}</div>
                                    <div className="event-year">{year}</div>
                                </div>
                                <div className="wrap-img">
                                    <div className="content-xyz">
                                        <img 
                                            className="img-event" 
                                            src={item.cover_img} alt={"#event-"+key} />
                                    </div>
                                </div>
                                <div className="wrap-info-event infoEvent" style={{ position: "relative" }}>
                                    <div className="row row-info-event">
                                        <div className="col-12">
                                            <div className="category-event cate-event">{item.topic}</div>
                                            <div className="name-event nameEvent">{item.name}</div>
                                            <div className="location-event locat-event">{item.location}</div>
                                            <div className="desc-event descEvent" style={{ wordBreak: "break-word" }}>
                                                {
                                                    smallDesc
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <span className="arrowEvent moreEvent">Xem thêm </span>
                                        <i className="detail-event">→</i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                )
            })
            var tempArr = []
            for (let i = 0; i < listEvent.length; i++) {
                if (i % 4 === 0 && i !== 0) {
                    tempArr.push(<div key={i+4} className="line-event"><hr></hr></div>)
                }
                tempArr.push(listEvent[i]);
            }
            listEvent = tempArr;
        }
        let title = "", content = ""
        if(this.props.texts.bodyText) {
            title = this.props.texts.bodyText.events.text.title
            content = this.props.texts.bodyText.events.text.content
        }
        return (
            <div className="wrap-event">
                <div className="image-head-event">
                    <div className="container-travel wrap-text-head event-header wrap-page-intro-block">
                        <h1>{title}</h1>
                        <p className="auto-next-line-p">{content}</p>
                    </div>
                </div>
                <div className="wrap-list-event">
                    <div className="container-travel container-travel-event cont-event">
                        <div className="row">
                            {listEvent}
                        </div>
                        {
                            (totalPage !== this.props.activePage && totalPage !== 0)
                            && <div className="col-12 text-center wrap-btn-support">
                                <button className="btn-contact btn btn-danger btn-more-home btn-more-list-event-page"
                                    style={{ color: "white", fontWeight: "bold"}}
                                    onClick={() => {
                                        this.addView();
                                    }}>
                                    <span>Xem thêm</span>
                                    <i className="icon-arrow-btn">→</i>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ListEvent;
