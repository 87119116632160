import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import PostSocialBar from '../common/PostSocialBar'
import PostView from '../common/PostView';

library.add(faPrint)
class Schedule extends Component {

    render() {
        let scheduleList
        if (this.props.dataTourInfo) {
            scheduleList = this.props.dataTourInfo.schedule.map((item, key) => {
                return (
                    <div key={key} className="wrap-step-tour">
                        <div className="number-step">
                            <div className="number-step-content">{key + 1}</div>
                        </div>
                        <div className="location-go">{item.title}</div>
                        <div className="location-desc">{item.content}</div>
                    </div>
                )
            })
        }
        return (
            <div>
                <h2 className="h3-tour-info">LỊCH TRÌNH TOUR</h2>
                {/* <h2 className="h3-tour-info">LỊCH TRÌNH TOUR  ({this.props.dataTourInfo && this.props.dataTourInfo.code}) </h2> */}
                {scheduleList}
                <div className="wrap-note-schedule">
                    <div className="row">
                        <div className="col-md-12 note-schedule">
                            <b>Lưu ý:</b>
                            <i>Thứ tự điểm thăm quan có thể thay đổi theo tình hình thực tể nhưng vẫn đảm bảo đầy đủ theo chương trình.</i>
                        </div>
                    </div>
                </div>
                <div className="wrap-schedule-footer">
                    {
                        this.props.dataTourInfo &&
                        <PostView views={this.props.dataTourInfo.views}/>
                    }
                    <PostSocialBar />
                </div>
            </div>
        );
    }
}
export default Schedule;
