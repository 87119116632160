import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/ListTourActions'
import ListTour from '../../components/tourPage/ListTour'
import { changeTabTitle } from '../../utils/common'

class ListTourContainer extends Component {
    componentDidMount() {
        changeTabTitle("Danh sách tour")
        this.props.getCategory()
        // Get all tours on first page
        //this.props.getList({ page: 1, cat: " " })
    }
    
    render() {
        return (
            <div>
                <ListTour {...this.props} />                
            </div>
        );
    }

}
const mapStateToProps = (store) => {

    return {
        dataCategory: store.cat.listCategoryTour,
        dataListTour: store.tour.listTour,
        // activePage: store.tour.activePage,
        totalPage: store.tour.totalPage,
        isLoadingData:store.tour.isFetching,
        texts: store.texts.listText,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        getCategory: () => {
            dispatch(actions.getCartegoryTour())
        },
        getList: (data) => {
            dispatch(actions.getListTour(data))
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTourContainer)