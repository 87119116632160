import React, { Component } from "react";
import { Dropdown } from "reactjs-dropdown-component";
import history from "../../history";
import PostContent from "../common/PostContent";
import PostSocialBar from "../common/PostSocialBar";
import { convertVietnameseToEnglishString } from "../../utils/common";

class UsefulInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			topic: [],
			postLinks: "",
			typeObjs: "",
			topicName: "",
			isSetTourFirstTime: true,
		};
	}

	componentDidMount() {
		let fixedTypes = ["Bạn cần biết", "Thông tin", "Về chúng tôi"];
		this.setState({
			topic: fixedTypes.map((item, key) => ({
				id: key,
				title: item,
				selected: false,
				key: `post-type-${key + 1}`,
				label: item,
				value: item,
			})),
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.dataTypeList && nextProps.dataUsefulInfo) {
			let tempArr = [];
			for (let i = 0; i < 4; i++) {
				let typeObj = nextProps.dataTypeList[i];
				if (!typeObj) continue;
				let type = typeObj.type;
				if (type === "US-Useful") {
					tempArr[0] = typeObj;
				} else if (type === "US-Info") {
					tempArr[1] = typeObj;
				} else if (type === "About-Us") {
					tempArr[2] = typeObj;
				}
				// Set list related tour first time
				if (this.state.isSetTourFirstTime) {
					let currentPost = typeObj.posts.find(
						(post) => post._id === nextProps.dataUsefulInfo._id
					);
					if (currentPost) {
						this.setState({
							isSetTourFirstTime: false,
							postLinks: typeObj,
						});
					}
				}
			}
			this.setState({ typeObjs: tempArr });
			//list data of dropdown show in the 1st time
			let paramsId = nextProps.dataUsefulInfo._id;
			for (let i = 1; i < 3; i++) {
				let item = nextProps.dataTypeList[i];
				if (item) {
					item.posts.forEach((element) => {
						if (element.id === paramsId) {
							this.setState({ postLinks: item });
							if (item.type === "US-Info") {
								this.setState({ topicName: "Bạn cần biết" });
							} else if (item.type === "US-Useful") {
								this.setState({ topicName: "Thông tin" });
							}
						}
					});
				}
			}
		}
	}

	resetThenSet = (id, key) => {
		// Initialize new dropdown with current value that user selected
		let temp = JSON.parse(JSON.stringify(this.state[key]));
		temp.forEach((item) => (item.selected = false));
		temp[id - 1].selected = true;
		// Set post link to reset post children
		this.setState({ postLinks: this.state.typeObjs[id - 1] });

		// Negate open first post when user choose about us on dropdown
		if (this.state.typeObjs[id - 1].type === "About-Us") {
			return;
		}

		if (this.state.typeObjs[id - 1]) {
			const firstPost = this.state.typeObjs[id - 1].posts[0];
			this.props.getUsefulInfo({ id: firstPost._id });
			// Change url according to post identity
			history.push(
				"/post/" +
					convertVietnameseToEnglishString(firstPost.title) +
					"-" +
					firstPost._id
			);
		}
	};

	onTopicChange = (item) => {
		// Set post link to reset post children
		this.setState({ postLinks: this.state.typeObjs[item.id] });

		// Negate open first post when user choose about us on dropdown
		if (this.state.typeObjs[item.id].type === "About-Us") {
			return;
		}

		if (this.state.typeObjs[item.id]) {
			const firstPost = this.state.typeObjs[item.id].posts[0];
			this.props.getUsefulInfo({ id: firstPost._id });
			// Change url according to post identity
			history.push(
				"/post/" +
					convertVietnameseToEnglishString(firstPost.title) +
					"-" +
					firstPost._id
			);
		}
	};

	convertDateTime(dt) {
		return (
			new Date(dt).getDate().toString().padStart(2, "0") +
			"/" +
			(new Date(dt).getMonth() + 1).toString().padStart(2, "0") +
			"/" +
			new Date(dt).getFullYear()
		);
	}

	render() {
		let listUsefulInfo;
		// let srcBgrImg
		let listType;
		let name,
			time,
			author,
			views = 0;

		if (this.props.dataUsefulInfo) {
			listUsefulInfo = this.props.dataUsefulInfo.description;
			name = this.props.dataUsefulInfo.title;
			time = this.convertDateTime(this.props.dataUsefulInfo.last_update_time);
			author = this.props.dataUsefulInfo.author;
			views = this.props.dataUsefulInfo.views;
			// const firstSrcImg = listUsefulInfo.match(/src="[^"]+/)
			// if (firstSrcImg)
			// srcBgrImg = firstSrcImg[0].toString().substring(5)
		}

		if (this.state.postLinks && this.state.postLinks.type === "About-Us") {
			listType = this.state.postLinks.posts.map((post, idx) => {
				return (
					<a
						href={post.url}
						key={`relevent-post-au-${idx}`}
						className="list-event-new"
					>
						<div className="row row-list-reference">
							<div className="col-md-4 no-padding img-reference">
								<img
									className="img-post-link"
									src="/images/logo_black.png"
									alt="nuocmy.net"
									style={{ objectFit: "fill" }}
								/>
							</div>
							<div className="col-md-8 content-reference">
								<div className="title-link-usa-full-info">
									<div>{post.title.toUpperCase()}</div>
								</div>
								<div className="desc-link">{post.description}</div>
							</div>
						</div>
					</a>
				);
			});
		}

		if (this.state.postLinks && this.state.postLinks.type !== "About-Us") {
			listType = this.state.postLinks.posts.map((post, idx) => {
				let smallDesc = "";
				const pArr = new DOMParser()
					.parseFromString(post.description, "text/html")
					.getElementsByTagName("p");
				for (let i = 0; i < pArr.length; i++)
					smallDesc += pArr[i].textContent ? pArr[i].textContent + " " : "";
				// Cannot use positive look behind on safari => Remove lookbehind
				let srcRegex = post.description.match(/(?:src=")[^"]+/);
				let firstImgDesc = "";
				if (srcRegex) {
					firstImgDesc = srcRegex.toString().substring(5); // Remove src="
				}
				return (
					<a
						href={
							"/post/" +
							convertVietnameseToEnglishString(post.title) +
							"-" +
							post._id
						}
						// onClick={() => this.changePost(post.id)}
						key={`relevent-post-${idx}`}
						className="list-event-new"
					>
						<div className="row row-list-reference">
							<div className="col-md-4 no-padding img-reference">
								<img className="img-post-link" src={firstImgDesc} alt={idx} />
							</div>
							<div className="col-md-8 content-reference">
								<div className="title-link-usa-full-info">
									<div>{post.title.toUpperCase()}</div>
								</div>
								{smallDesc && (
									<div className="desc-link">
										{smallDesc.length > 100
											? smallDesc.substring(0, 100) + "..."
											: smallDesc}
									</div>
								)}
							</div>
						</div>
					</a>
				);
			});
		}

		return (
			<div className="wrap-event-info use-full-link-info">
				<div className="wrap-event-name">
					<h3 className="bolder-title main-type-color">
						{this.state.topicName.toUpperCase()}
					</h3>
				</div>
				<div className="container-travel">
					<div className="title-center"></div>
					<div className="row row-use-full-page">
						<div className="col-md-12 col-lg-8">
							<div>
								<h3 className="bolder-title">{name}</h3>
								<h6 className="post-infor-row">
									{/* <i className="post-infor-header">Đăng ngày: </i> */}
									{time}
								</h6>
								<h6 className="post-infor-row">
									{/* <i className="post-infor-header">Tác giả: </i> */}
									{author}
								</h6>
								<h6 className="post-infor-row">
									{/* <i className="post-infor-header">Lượt xem: </i> */}
									{views + " lượt xem"}
								</h6>
							</div>
							<PostContent content={listUsefulInfo} />
							<div style={{ margin: "10px 0" }}>
								<PostSocialBar />
							</div>
						</div>

						{/* <div className="col-md-1"></div> */}
						<div className="col-md-12 col-lg-4 new-col-usefull">
							<div className="moreInfoEvent">
								<div className="wrap-usas">
									<Dropdown
										title="CHỦ ĐỀ"
										className="drop-post"
										list={this.state.topic}
										onChange={this.onTopicChange}
										styles={{
											checkIcon: { display: "none" },
										}}
									/>
								</div>
								<div
									className="wrap-new-event-info UsefullNewEvent"
									style={{ marginTop: "calc(100vw*20/1434)" }}
								>
									{listType}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default UsefulInfo;
