import React from 'react'
import { sharingFacebook } from '../../utils/common'
import "../../styles/social-bar.css"

export default class PostSocialBar extends React.PureComponent {

    handleClickShareFacebook = () => {
        // const shareObj = this.props.shareObj
        // if(shareObj) {
        //     sharingToFBWithContent(shareObj)
        // } else {
            sharingFacebook()
        // }
    }

    render = () => {
        return (
            <div className="wrap-post-social">
                <div 
                    className="fb-like" 
                    data-href={window.location.href} 
                    data-width="" 
                    data-layout="standard" 
                    data-action="like" 
                    data-size="large" 
                    data-show-faces="true" 
                    data-share="true">
                </div>
                {/* <div className="custom-fb-btn" onClick={this.handleClickShareFacebook}>
                    <div className="fb-btn-img">
                        <div className="fb-btn-img-txt">f</div>
                    </div>
                    <p className="fb-btn-txt">Chia sẻ</p>
                </div> */}
            </div>
        )
    }
}