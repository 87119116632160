import { put, takeEvery } from 'redux-saga/effects';
import TourMore from '../fetchAPI/getTourMore'
import getTourInfo from '../fetchAPI/getTourInfo'
import * as types from '../constant';
function* getInfoTour(action) {
  try {
    const res = yield getTourInfo(action.payload);
    yield put({
      type: types.GET_TOURINFO_SUCCESS,
      payload: res
    });

    yield put({
      type: types.GET_TOURMORE_REQUEST,
      payload: { id: action.payload, type: res.tour.type }
    });

  } catch (error) {
    yield put({
      type: types.GET_TOURINFO_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* getTourMore(action) {
  try {
    const res = yield TourMore(action.payload.id, action.payload.type);
    yield put({
      type: types.GET_TOURMORE_SUCCESS,
      payload: res
    });
  } catch (error) {

    yield put({
      type: types.GET_TOURMORE_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
export const TourInfoSaga = [
  takeEvery(types.GET_TOURINFO_REQUEST, getInfoTour),
  takeEvery(types.GET_TOURMORE_REQUEST, getTourMore),
];