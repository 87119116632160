import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
// var backMore = '/images/slide3.jpg';
class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: []
        };
    }

    toggle(number) {
        // Change status of clicked row
        let newCollapse = this.state.collapse.map((e, idx) => idx === number ? !e : e);
        this.setState({ collapse: newCollapse });
    }

    componentWillReceiveProps(nextProps) {
        // Reset value of collapse to a new array contains all false value
        // Example: If length of list data is 3 => collapse = [false, false, false] 
        if (nextProps.listData) {
            this.setState({ collapse: nextProps.listData.map(e => false) });
        }
    }

    render() {
        let listDatas = [];
        if (this.props.listData) {
            listDatas = this.props.listData.map((item, key) => {
                return (
                    <div className="wrap-faq-child" key={key} onClick={() => this.toggle(key)}>
                        <div className="row row-faq">
                            <div className="col-9 col-9-faq" >
                                <span className="title-faq-child">{item.question}</span>
                            </div>
                            <div className="col-3">
                                <div className="btn-show-faq .btn-show-faq-res">
                                    <span className="icon-show-more-faq">{this.state.collapse[key] ? '-' : '+'}</span>
                                </div>
                            </div>
                        </div>

                        <Collapse isOpen={this.state.collapse[key]}>
                            <Card>
                                <CardBody>
                                    <p className="desc-us-p" style={{ color: "#777777" }}>{item.answer}</p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                );
            });
        }
        let title = "", content = ""
        if(this.props.texts.bodyText) {
            title = this.props.texts.bodyText.faq.text.title
            content = this.props.texts.bodyText.faq.text.content
        }
        return (
            <div className="wrap-faqs">
                <div className="image-head-faq" style={{
                    // backgroundImage: 'url(' + backMore + ')'
                }}>
                    <div className="container-travel wrap-text-head-faqs wrap-page-intro-block">
                        <div style={{ padding: "0 15px" }}>
                        <h1>{title}</h1>
                        <p className="auto-next-line-p">{content}</p>
                        </div>
                    </div>

                </div>
                <div className="list-faq">
                    <div className="container-travel container-faqs">
                        {listDatas}
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQ;
