import React, { Component } from 'react'
import { getFormatedInputDate } from '../../utils/common'
// import { convertVietnameseToEnglishString } from '../../utils/common'

class Service extends Component {

    handleOrderClick(isSelfRequest = false) {
        if (this.props.dataTourInfo) {
            // Save link and name of tour to storage
            // For getting at price request page
            const { type, _id, name, reference_event_date } = this.props.dataTourInfo
            localStorage.setItem('orderSelectedId', _id)
            localStorage.setItem('orderSelectedType', type)
            localStorage.setItem('orderSelectedName', name)
            localStorage.setItem(
                'orderSelectedTime',
                isSelfRequest
                ? "" 
                : getFormatedInputDate(reference_event_date)
            )
        }
    }

    render() {
        let serviceList
        if (this.props.dataTourInfo) {
            let {
                special_point, not_include_services,
                include_services, reference_event_link, price
            } = this.props.dataTourInfo
            serviceList = (
                <div className="wrap-service">

                    <div>
                        {
                            price &&
                            <div className="wrap-ref-event">
                                <span className="desc-part">CHI PHÍ TỪ</span>
                                <div>
                                    <span className="price-part">
                                        {/* &nbsp; */}
                                        {"$" + price.toFormatPrice()}
                                    </span>
                                    <span className="separate-part">
                                        &nbsp;/
                                    </span>
                                    <span className="unit-part">pax</span>
                                </div>
                            </div>
                        }
                        {/* <div className="wrap-ref-evt-btn">
                            <a
                                target="nuocmy_sub_frame"
                                href="/price-request"
                                className="btn-contact btn btn-request-price-tour">
                                <span>YÊU CẦU GIÁ ĐẠI LÝ, ĐỐI TÁC&nbsp;</span>
                                <i>→</i>
                            </a>
                        </div> */}
                       
                        <div className="wrap-ref-evt-btn">
                            {
                                reference_event_link 
                                ?
                                <a
                                    target="nuocmy_sub_frame"
                                    href={reference_event_link}
                                    className="btn-contact btn btn-request-price-tour">
                                    <span style={{ color: "white" }}>XEM CHI TIẾT LỊCH KHỞI HÀNH&nbsp;</span>
                                    <i>→</i>
                                </a>
                                :
                                <a
                                    target="nuocmy_sub_frame"
                                    href="/price-request"
                                    className="btn-contact btn btn-request-price-tour"
                                    onClick={() => this.handleOrderClick(true)}>
                                    <span style={{ color: "white" }}>KHỞI HÀNH THEO YÊU CẦU&nbsp;</span>
                                <i>→</i>
                            </a>
                            }
                        </div>
                    </div>

                    <div className="title-serive">ĐIỂM NỔI BẬT</div>
                    <div className="desc-serive">{special_point}</div>
                    <div className="title-serive">NỘI DUNG DỊCH VỤ</div>
                    <div className="desc-serive">{include_services}</div>
                    <div className="title-serive">GHI CHÚ</div>
                    <div className="desc-serive">{not_include_services}</div>
                    <div style={{ textAlign: "center" }}>
                        <a
                            style={{
                                // display: "flex",//dev_bui_hieu
                                justifyContent: "center"
                            }}
                            target="nuocmy_sub_frame"
                            href="/price-request"
                            className="btn-contact btn btn-danger btn-more-home btn-price btn-request-price-tour"
                            onClick={() => this.handleOrderClick()}>
                            <span>ĐẶT TOUR NÀY</span>
                            <i className="icon-arrow-btn">→</i>
                        </a>
                    </div>
                </div>
            )
        }
        return (
            <div className="service-tour-info">
                {serviceList}
            </div>
        );
    }
}
export default Service;
