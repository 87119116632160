import * as types from "../../constant";

const DEFAULT_STATE = {
    events: [],
    posts: [],
    googleObj: null,
    openGoogleResultModal: false,
    // keySearch: null
}

export default (state = DEFAULT_STATE, action) => {
    
    switch (action.type) {
        // case types.GET_NEW_EVENTS_HEADER_REQUEST:
        //     break
        // case types.CALL_GOOGLE_SEARCH_REQUEST:
        //     return {
        //         ...state,
        //         keySearch: action.payload
        //     }

        case types.GET_NEW_POSTS_HEADER_REQUEST:
            return {
                ...state,
            }
        case types.TURN_OFF_GOOGLE_MODAL:
            return {
                ...state,
                openGoogleResultModal: false
            }
        case types.CALL_GOOGLE_SEARCH_SUCCESS:
            return {
                ...state,
                googleObj: action.payload,
                openGoogleResultModal: true,

            }
        case types.GET_NEW_EVENTS_HEADER_SUCCESS:
            return {
                ...state,
                events: action.payload
            }
        case types.GET_NEW_POSTS_HEADER_SUCCESS:
            return {
                ...state,
                posts: action.payload
            }
        case types.GET_NEW_POSTS_HEADER_FAILURE:
            return {
                ...state,
                posts: []
            }
        case types.GET_NEW_EVENTS_HEADER_FAILED:
            return {
                ...state,
                events: []
            }
        default:
            return state;
    }
}
