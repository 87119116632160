import React from 'react'
import { 
    Modal, 
    Header, 
    Image 
} from 'semantic-ui-react'

export default class ErrorModal extends React.Component {

    state = {
        isOpen: false,
        error: ""
    }

    open = (error) => {
        this.setState({ 
            isOpen: true,
            error
        })
    }

    close = () => this.setState({ isOpen: false })

    render = () => {
        return (
            <Modal
                open={this.state.isOpen}
                onClose={this.close}
                size="tiny"
                className="noti-modal"
                centered={false}>
                <Image
                    size="tiny"
                    centered
                    src="./images/error_icon.png" />
                <Modal.Content>
                    <Modal.Description>
                        <Header textAlign="center" className="fixed-font">
                            KHÔNG THỂ THỰC HIỆN YÊU CẦU
                        </Header>
                        <p className="noti-modal-text">
                            {this.state.error}
                        </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}