import * as types from '../constant';
const DEFAULT_STATE = {
  usefulInfo: null,
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_USEFULINFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_USEFULINFO_SUCCESS:
    
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        usefulInfo: action.payload.post
      };
    case types.GET_USEFULINFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};