import { put, takeEvery } from 'redux-saga/effects';
import get from '../../fetchAPI/FAQ/get';
import * as types from '../../constant';
function* getText() {
  try {
    const res = yield get();
    yield put({
      type: types.GET_LIST_FAQ_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_LIST_FAQ_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const FAQSaga = [
  takeEvery(types.GET_LIST_FAQ_REQUEST, getText)
];
