import { GOOGLE_CUSTOM_SEARCH_DOMAIN } from "../../constant";

export default (keyword, page) => {
	return new Promise((resolve, reject) => {
		let pageCurr;
		if (page) {
			pageCurr = page * 10 - 9;
		} else {
			pageCurr = 1;
		}
		const url =
			GOOGLE_CUSTOM_SEARCH_DOMAIN + "&q=" + keyword + "&start=" + pageCurr;
		// console.log("URL", url)
		fetch(url, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((res) => {
				// console.log(res);
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
