import React from 'react';
import "../styles/usefullinfo.css"
import USBeautyContainer from '../containers/USBeautyContainers/USBeautyContainer';
class USBeautyPage extends React.Component {
  render() {
    return (
      <div className="useful">
        <USBeautyContainer />
      </div>
    );
  }
}

export default USBeautyPage;