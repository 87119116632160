import React, { Component } from "react";

export default class PostView extends Component {
	render() {
		return (
			<div className="wrap-post-views">
				<p>{(this.props.views || 0) + " lượt xem"}</p>
			</div>
		);
	}
}
