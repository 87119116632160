import * as types from '../constant';
export function getTourInfo(payload){
  return ({
    type: types.GET_TOURINFO_REQUEST,
    payload
  });
}
export function getTourMore(payload){
  return ({
    type: types.GET_TOURMORE_REQUEST,
    payload
  });
}