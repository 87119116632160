import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/EventInfoActions'
import EventInfo from '../../components/EvenInfoPage/EventInfo'
import { getIdFromUrl, changeTabTitle } from '../../utils/common'

class EventInfoContainer extends Component {

    state = {}

    static getDerivedStateFromProps = (nextProps) => {
        if(nextProps.dataEventInfo) {
          changeTabTitle(nextProps.dataEventInfo.name)
        }
        return nextProps
    }

    componentDidMount() {
        const id = getIdFromUrl()
        this.props.getEventInfo(id)
        this.props.getEventNew(id)
    }

    onChangingUrl (id){
        this.props.getEventInfo(id);
        this.props.getEventNew(id);
    }

    render() {
        return (
            <EventInfo onChangingUrl={(id) => this.onChangingUrl(id)} {...this.props} />
        );
    }

}
const mapStateToProps = (store) => {
    return {
        dataEventInfo: store.eventInfo.eventInfo,
        dataEventNew: store.eventNew.eventNew,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getEventInfo: (data) => {
            dispatch(actions.getEventInfo(data))
        },
        getEventNew: (data) => {
            dispatch(actions.getEventNew(data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventInfoContainer)