import * as types from '../constant';
//actions get slide and background
export function getImageDefault(payload){
  return ({
    type: types.GET_IMAGE_REQUEST,
    payload
  });
}
//get text newevent and tourschedule
export function getTextNew(payload){
  return ({
    type: types.GET_TEXT_REQUEST,
    payload
  });
}
//get Image Tour
export function getImageTour(payload){
  return ({
    type: types.GET_IMAGE_TOUR_REQUEST,
    payload
  });
}
//get Image Event
export function getImageEvent(payload){
  return ({
    type: types.GET_IMAGE_EVENT_REQUEST,
    payload
  });
}