import { domain } from '../../constant'

export default function AddContactAPI(payload) {
    let data = {
        full_name: payload.fullName,
        email:payload.email,
        tel:payload.phoneNum,
        other_contact:payload.otherContact,
        description:payload.note
    }
    return new Promise((resolve, reject) => {
         const url = domain + "contact"
        fetch(url, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error);



            });
    });
}