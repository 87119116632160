import * as types from "../../constant";
import { put, takeEvery } from 'redux-saga/effects'
import addRequestAPI from '../../fetchAPI/PriceRequest/AddRequestAPI'

function* addRequest(action) {
    try {
        const listRequest = yield addRequestAPI(action.payload);
        yield put({
            type: types.ADD_PRICEREQUESR_SUCCESS,
            payload: listRequest
        })
    } catch (error) {
        yield put({
            type: types.ADD_PRICEREQUESR_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
export const RequestSaga = [
    takeEvery(types.ADD_PRICEREQUEST_REQUEST, addRequest)
];

