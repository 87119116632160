import { domain } from '../../constant'
export default function callApi(activePage, topic, postId, numberOfPosts) {
  return new Promise((resolve, reject) => {
    let url = domain + "get-us-post-by-type/" + topic + "/" + numberOfPosts + "/" + activePage + "/" + postId
    console.log(url)
    fetch(url, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}