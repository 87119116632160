import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/ListEventActions'
import ListEvent from '../../components/eventPage/ListEvent'
import { changeTabTitle } from '../../utils/common'

class ListEventContainer extends Component {

    componentDidMount() {
        // Change tab title
        changeTabTitle("Danh sách sự kiện")

        // Negate get events at last page (if we're already got that events)
        if (this.props.activePage === 1) {
            this.props.getList({ page: this.props.activePage })
        }
    }

    render() {
        return (
            <ListEvent {...this.props} />
        );
    }
}
const mapStateToProps = (store) => {
    return {
        dataListEvent: store.event.listEvent,
        activePage: store.event.activePage,
        totalPage: store.event.totalPage,
        isLoadingData:store.event.isFetching,
        texts: store.texts.listText,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getList: (data) => {
            dispatch(actions.getListEvent(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListEventContainer)