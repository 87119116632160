import { domain } from '../../constant'
export default function callApi() {
    return new Promise((resolve, reject) => {
      const url = domain+"get-members";
      fetch(url, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    