import React from 'react'
import "../styles/price-request.css"
import PriceRequestContainer from '../containers/PriceRequestPage/PriceRequestContainer'
class PriceRequestPage extends React.Component {
  render() {
    return (
      <div className="price-request">
        <PriceRequestContainer />
      </div>
    );
  }
}

export default PriceRequestPage;