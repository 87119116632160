import React, { Component } from 'react';
import { convertVietnameseToEnglishString } from '../../utils/common';

class TourSchedule extends Component {
    render() {
        // Background and text
        let backgroundObj = { src: "", alt: "" };
        let tour = { title: "", content: "" };
        if (this.props.images && this.props.images.backgrounds) {
            backgroundObj = this.props.images.backgrounds[0];
        }
        if (this.props.texts.bodyText) {
            tour = this.props.texts.bodyText.homepage_tour.text
        }
        // Image Event
        let tours = [];
        if (this.props.imageTour) {
            tours = this.props.imageTour.tours.map((item, key) => {
                return (
                    <div className="col-lg-3 col-md-6 home-4-tours" key={key}>
                        <div className="wrap-tour">
                            <a
                                style={{ color: "black", textDecoration: "none" }}
                                href={"tour-info/" + convertVietnameseToEnglishString(item.name) + "-" + item._id} >
                                <img className="img-tour" src={item.cover_img.src} alt={item.cover_img.alt} />
                                <div className="tour-item-bg"></div>
                                <div className="wrap-info-tour">
                                    <div className="row">
                                        <div className="col-9 text-tour">
                                            <div className="number-day">{item.duration} ngày</div>
                                            <div className="name-tour">{item.name}</div>
                                        </div>
                                        <div className="col-3">
                                            <i className="detail-tour">→</i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className="">
                <div className="new-tour">
                    <div className="container-travel text-center wrap-page-intro-block">
                        <span>
                            <h1>{tour.title.toUpperCase()}</h1>
                            <p className="m-0 auto-next-line-p">{tour.content}</p>
                        </span>
                    </div>
                </div>
                <div className="row row-tour">
                    {tours}
                </div>
                <div className="wrap-bgr-img">
                    <a href={backgroundObj.link} className="more-event">
                        <img src={backgroundObj.src} alt={backgroundObj.alt} />
                        <div className="overlay"></div>
                        <div className="wrap-bgr-img-title">
                            <div className="bgr-img-title" >
                                <span>{backgroundObj.title}&nbsp;</span>
                                <i>→</i>
                            </div>
                        </div>
                    </a>
                    <a
                        href="/tours"
                        className="bgr-btn btn-more-event btn btn-more-home">
                        <span>
                            Tìm hiểu thêm
                        </span>
                        <i className="icon-arrow-btn">→</i>
                    </a>
                </div>
            </div>
        );
    }
}

export default TourSchedule;
