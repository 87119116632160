import * as types from '../constant';

export function getNewEvents() {
  return {
    type: types.GET_NEW_EVENTS_HEADER_REQUEST
  }
}

export function getNewPosts() {
  return {
    type: types.GET_NEW_POSTS_HEADER_REQUEST
  }
}

export function getTourTypes(){
  return {
    type: types.GET_TOURCATEGORY_REQUEST
  }
}

export function getUSAPostTopics(){
  return {
    type: types.GET_CATEGORY_USA_REQUEST
  }
}