import React, { Component } from 'react';
import Highlight from '../../components/highlightTourPage/Highlight'
import * as actions from '../../actions/HighlightTour'
import {connect} from 'react-redux';

class HighlightContainer extends Component {
    componentDidMount() {
        this.props.initLoad();
    }

    render() {
        return (
            <Highlight {...this.props} />
        );
    }

}

const mapStateToProps =(state)=> {
    return{
        data: state.highlight.listData,
        texts: state.texts.listText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        initLoad:()=>{
            dispatch(actions.getDataHL());
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps) (HighlightContainer);
