import { combineReducers } from 'redux';
import ImageDefaultReducer from './HomePageReducer/ImageDefaultReducer';
import TextNewReducer from './HomePageReducer/TextNewReducer';
import ImageEventReducer from './HomePageReducer/ImageEventReducer';
import ImageTourReducer from './HomePageReducer/ImageTourReducer';
import AddRequestReducer from "./PriceRequestReducer/AddRequestReducer"
import AddContactReducer from "./ContactPageReducer/AddContactReducer"
import FAQReducer from './FAQ/FAQReducer';
import HighlightTourReducer from '../reducers/HighlightTourReducer'
import AboutReducer from './AboutPage/AboutReducer';
import CategoryTourReducer from './CategoryTourReducer'
import ListTourReducer from './ListTourReducer'
import TourInfoReducer from './TourInfoReducer'
import TourMoreReducer from './TourMoreReducer';
import ListEventReducer from './ListEventReducer';
import EventInfoReducer from './EventInfoReducer';
import EventNewReducer from './EventNewReducer';
import UsefulInfoReducer from './UsefulInfoReducer';
import TypeListRedcer from './TypeListRedcer';
import getCategoryUsa from './Usa/getCategoryUsa';
import getUsaReducer from './Usa/getUsaReducer';
import HeaderReducer from './common/HeaderReducer'
import IntroReducer from './AboutPage/IntroReducer';
import TermReducer from './TermPage/TermReducer'
import { loadingReducer } from './common/loading';

export default combineReducers({
  cat:CategoryTourReducer,
  tour:ListTourReducer,
  imageDault :ImageDefaultReducer,
  texts : TextNewReducer,
  imageEvent : ImageEventReducer,
  imageTour : ImageTourReducer,
  PriceRequest: AddRequestReducer,
  AddContact: AddContactReducer,
  list : FAQReducer,
  highlight:HighlightTourReducer,
  listAbout : AboutReducer,
  listIntro:IntroReducer,
  tourInfo:TourInfoReducer,
  tourMore:TourMoreReducer,
  event:ListEventReducer,
  eventInfo:EventInfoReducer,
  eventNew:EventNewReducer,
  useful:UsefulInfoReducer,
  listType:TypeListRedcer,
  usaCategory:getCategoryUsa,
  listUsa:getUsaReducer,
  headerReducer: HeaderReducer,
  terms: TermReducer,
  loading:loadingReducer
});
