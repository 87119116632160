import React, { Component } from 'react';
import GoogleSearchComponent from '../../components/GoogleSearchPage'
import { connect } from 'react-redux';
import { CALL_GOOGLE_SEARCH_REQUEST } from '../../constant';

class GoogleSearchContainer extends Component {
    componentDidMount() {
        // Get params in URL

        let searchPath = window.location.search
        if (!searchPath) return
        let searchItem = searchPath.substring(1).split("&").find(item => item.split("=")[0] === "q")
        if (!searchItem) return
        // Call api with the params got from url
        this.props.callGoogleCustomSearch(searchItem.substring(2))
    }

    render() {
        return (
            <GoogleSearchComponent {...this.props} />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        googleObj: state.headerReducer.googleObj,
        keySearch: state.headerReducer.keySearch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        callGoogleCustomSearch: (keySearch, page) => {
           
            dispatch({ type: CALL_GOOGLE_SEARCH_REQUEST, payload: keySearch, page })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSearchContainer)