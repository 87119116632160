import { domain } from '../../constant'

export default function AddRequest(payload) {
    let data = {
        schedule: payload.schedule,
        exp_arr_date: payload.expDate,
        numb_of_people: payload.numOfPeople,
        can_speak_vie: payload.canSpeakVie,
        has_tour_guide: payload.hasTourGui,
        full_name: payload.name,
        email: payload.email,
        tel: payload.phoneNum,
        company: payload.company,
        other_services: payload.note,
        other_contact: payload.other_contact,
        market: payload.market,
        position: payload.position,
        license: payload.license,
        is_self_host: payload.isSelfHost,
        tour_type: payload.tourType
    }
    return new Promise((resolve, reject) => {
        const url = domain + "order"
        fetch(url, {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);



            });
    });
}