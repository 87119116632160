import React, { Component } from 'react';
import UsaNew from '../../components/usaPage/USAs_NEW'
import * as actions from '../../actions/UsaAction';
import { connect } from 'react-redux';
class UsasContainer extends Component {
  componentDidMount() {
    this.props.getCategory()
    // this.props.getData({ page: 1, topic: " " })
  }
  render() {
    return (
      <div>
        <UsaNew {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataCategory: state.usaCategory.listCategoryUsa,
    listData: state.listUsa.listUsaData,
    activePage: state.listUsa.activePage,
    totalPage: state.listUsa.totalPage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategory: (data) => {
      dispatch(actions.getCategory(data))
    },
    getData: (data) => {
      dispatch(actions.getData(data))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsasContainer);
