import * as types from '../constant';
const DEFAULT_STATE = {
  listType: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_LISTTYPE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LISTTYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage:null,
        listType: action.payload.post_types
      };
    case types.GET_LISTTYPE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};