import * as types from '../constant';
export function getListTour(payload){
  return ({
    type: types.GET_TOURLIST_REQUEST,
    payload
  });
}

export function getCartegoryTour(payload){
    return ({
      type: types.GET_TOURCATEGORY_REQUEST,
      payload
    });
  }