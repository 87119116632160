import React from 'react'
import "../styles/contact.css"
import ContactContainer from '../containers/ContactPage/ContactContainer'
class ContactPage extends React.Component {
  render() {
    return (
      <div className="FAQ">
        <ContactContainer />
      </div>
    );
  }
}

export default ContactPage;