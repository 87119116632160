import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/UsefulInfoActions'
import * as actionsBeauty from '../../actions/UsaAction'
import USBeauty from '../../components/usBeauty/USBeauty'
import { getIdFromUrl } from '../../utils/common'

class USBeautyContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            postId: "fakeId"
        }
    }

    componentDidMount() {
        this.props.getTypeList();
        const id = getIdFromUrl()
        this.props.getUsefulInfo({ id })
        this.props.getData({ page: 1, topic: " ", postId: id, numberOfPosts: 5 })
        this.setState({ postId: id })
    }

    render() {
        return (
            <USBeauty
                activePostId={this.state.postId}
                {...this.props} />
        );
    }
}
const mapStateToProps = (store) => {
    return {
        dataUsefulInfo: store.useful.usefulInfo,
        isLoadingData: store.useful.isFetching,
        isLoading: store.listType.isFetching,
        dataTypeList: store.usaCategory.listCategoryUsa,
        listData: store.listUsa.listUsaDataSwap,
        activePage: store.listUsa.activePage,
        totalPage: store.listUsa.totalPage
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUsefulInfo: (data) => {
            dispatch(actions.getUseInfo(data))
        },
        getTypeList: (data) => {
            dispatch(actions.getTypeList(data))
        },
        getData: (data) => {
            dispatch(actionsBeauty.getData(data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(USBeautyContainer)
