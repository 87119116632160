import React from 'react'
import "../styles/about-us.css"
import AboutUsContainer from '../containers/AboutUsPage/AboutUsContainer'
class AboutUsPage extends React.Component {
  render() {
    return (
      <div className="FAQ">
        <AboutUsContainer />
      </div>
    );
  }
}

export default AboutUsPage;