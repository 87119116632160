import { put, takeEvery } from 'redux-saga/effects';
import getTermAPI from '../../fetchAPI/TermPage/GetTermAPI'
import * as types from '../../constant';
function* getTerm() {
  try {
    const res = yield getTermAPI();
    yield put({
      type: types.GET_TERM_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_TERM_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const TermSaga = [
  takeEvery(types.GET_TERM_REQUEST, getTerm)
];
