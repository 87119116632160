import React, { Component } from "react"
import { Loader, Dimmer } from 'semantic-ui-react'

export default class LoadingModal extends Component {
	render = () => {
		return (
			<Dimmer active page>
				<Loader
					size="small"
					content="Đang tải"
				/>
			</Dimmer>
		)
	}
}

// export default class LoadingModal extends Component {

// 	render() {
// 		return (
// 			<div style={{
// 				background: "rgba(0, 0, 0, 0.5)",
// 				width: "100%",
// 				height: "100%",
// 				margin: "0px auto",
// 				borderRadius: 5,
// 				position: "fixed",
// 				top: 0,
// 				left: 0
// 			}}>
// 				<div className="center-modal-title" style={{ textAlign: "center" }}>
// 					<img
// 						alt="Loading ..."
// 						style={{
// 							position: "fixed",
// 							top: "50%",
// 							left: "50%",
// 							transform: "translate(-50%,-50%)"
// 						}}
// 						width="100"
// 						height="100"
// 						src="/images/loading.gif" />
// 					{/* 
// 						<h4 className="center-modal-title">Loading...</h4>
// 						<p>Please wait</p> 
// 					*/}
// 				</div>
// 			</div>
// 		)
// 	}
// }