import { put, takeEvery } from 'redux-saga/effects';
import getCategory from '../fetchAPI/getCategoryTour';
import getTourFollowByTypeAPI from '../fetchAPI/getTourFollowByType';
import getList from '../fetchAPI/getListTour'
import * as types from '../constant';
function*getCategoryTour() {
  try {
    const res = yield getCategory();
    yield put({
      type: types.GET_TOURCATEGORY_SUCCESS,
      payload: res
    });
  } catch (error) {

    yield put({
      type: types.GET_TOURCATEGORY_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function*getTourFollowByType() {
  try {
    const res = yield getTourFollowByTypeAPI();
    yield put({
      type: types.GET_TOUR_FOLLOW_TYPE_SUCCESS,
      payload: res
    });
  } catch (error) {

    yield put({
      type: types.GET_TOUR_FOLLOW_TYPE_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function*getListTour(action) {
  try {
    const response = yield getList(action.payload.page, action.payload.cat);
    // Trigger callback function to set tour type
    if(action.payload.cb && response.tours.length) {
      action.payload.cb(response.tours[0].type)
    }
    yield put({
      type: types.GET_TOURLIST_SUCCESS,
      payload: {...response, page: action.payload.page}
     
    } );
  } catch (error) {
    
    yield put({
      type: types.GET_TOURLIST_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const ListTourSaga = [
  takeEvery(types.GET_TOURCATEGORY_REQUEST, getCategoryTour),
  takeEvery(types.GET_TOURLIST_REQUEST, getListTour),
  takeEvery(types.GET_TOUR_FOLLOW_TYPE_REQUEST, getTourFollowByType)
];