import React from 'react'
import "../styles/event-info.css"
import EventInfoContainer from '../containers/EventInfoPage/EventInfoContainer'

const EventInfoPage = (routerObj) => {
    return (
      <div className="event-info">
        <EventInfoContainer routerObj={routerObj}/>
      </div>
    );
}

export default EventInfoPage;