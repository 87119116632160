import React, { Component } from 'react';
import { connect } from "react-redux"
import PriceRequest from '../../components/priceRequestPage/PriceRequest'
import { addPriceRequest, resetAction } from "../../actions/PriceRequestAction"
import { GET_TOUR_FOLLOW_TYPE_REQUEST, SELECT_TOUR_TYPE, OTHER_TOUR_TYPE } from '../../constant';
class PriceRequestContainer extends Component {
    componentDidMount = () => this.props.initLoad()
    render() {
        return (
            <PriceRequest {...this.props} />
        );
    }
}
const mapStateToProps = (store) => {
    return {
        isAdded: store.PriceRequest.isAdded,
        isSending: store.PriceRequest.isSending,
        texts: store.texts.listText,
        tourCategory: store.cat.listCategoryTour.concat({ type: OTHER_TOUR_TYPE }),
        tourByType: store.cat.activeListTourByType,
    }
}
const mapDispacthToProps = (dispatch) => {
    return {
        initLoad: () => dispatch({ type: GET_TOUR_FOLLOW_TYPE_REQUEST }),
        sendRequest: (payload) => {
            dispatch(addPriceRequest(payload))
        },
        resetAction: () => {
            dispatch(resetAction())
        },
        selectTourType: payload => dispatch({ type: SELECT_TOUR_TYPE, payload })
    }
}
export default connect(mapStateToProps, mapDispacthToProps)(PriceRequestContainer)