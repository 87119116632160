import React, { Component } from 'react';
import { connect } from 'react-redux'
// import * as actions from '../../actions/ListTourInfoActions'
import Slide from '../../components/tourInfoPage/Slide'
class SlideContainer extends Component {
    render() {
        return (
            <Slide {...this.props}/>
        );
    }

}
const mapStateToProps = (state) => {
    return {
      dataTourInfo:state.tourInfo.tourInfo
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
      
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(SlideContainer);

// export default SlideContainer;
