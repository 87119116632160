import { all } from 'redux-saga/effects';
import { ImageDefaultSaga } from './HomePageSaga/ImageDefaultSaga'
import { TextNewSaga } from './HomePageSaga/TextNewSaga'
import { ImageEventSaga } from './HomePageSaga/ImageEventSaga'
import { ImageTourSaga } from './HomePageSaga/ImageTourSaga'
import { RequestSaga } from "./PriceRequestSaga/AddRequestSaga"
import { ContactSaga } from "./ContactPageSaga/AddContact"
import { HighlightTourDataSaga } from '../sagas/HighlightTourSaga'
import { FAQSaga } from './FAQ/FAQSaga'
import { getAbout } from './AboutPage/getAbout'
import { ListTourSaga } from './ListTourSaga';
import { TourInfoSaga } from './TourInfoSaga';
import { ListEventSaga } from './ListEventSaga';
import { EventInfoSaga } from './EventInfoSaga';
import { UsefulInfoSaga } from './UsefulInfoSaga';
import { getCategoryUsa } from './UsaSaga/getCategoryUsa';
import { getListUsa } from './UsaSaga/getListUsa';
import { HeaderSaga } from './HeaderSaga';
import { TermSaga } from './TermPage/TermSaga'
import { GoogleSaga } from './GoogleSaga'


function* rootSaga() {
  yield all([
    ...FAQSaga,
    ...ImageDefaultSaga,
    ...TextNewSaga,
    ...ImageEventSaga,
    ...ImageTourSaga,
    ...RequestSaga,
    ...ContactSaga,
    ...HighlightTourDataSaga,
    ...ListTourSaga,
    ...getAbout,
    ...TourInfoSaga,
    ...ListEventSaga,
    ...EventInfoSaga,
    ...UsefulInfoSaga,
    ...getCategoryUsa,
    ...getListUsa,
    ...HeaderSaga,
    ...TermSaga,
    ...GoogleSaga
  ]);
}
export default rootSaga;
