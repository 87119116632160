import React, { Component } from 'react';
import { connect } from "react-redux"
import Terms from '../../components/termsPage/Terms'
import * as actions from "../../actions/TermAction"
class TermsContainer extends Component {
    componentDidMount() {
        this.props.getTerm();
    }
    render() {
        return (
            <Terms {...this.props} />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        terms: state.terms.listTerm
    }
}
const mapDispacthToProps = (dispatch) => {
    return {
        getTerm: () => {
            dispatch(actions.getTerm())
        }
    }
}
export default connect(mapStateToProps, mapDispacthToProps)(TermsContainer)