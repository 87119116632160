import { put, takeEvery } from 'redux-saga/effects';
import getUseful from '../fetchAPI/getUsefulInfo'
import getType from '../fetchAPI/getTypeList'
import * as types from '../constant';

function*getUsefulInfo(action) {
  try {
    const respone = yield getUseful(action.payload.id);
    
    yield put({
      type: types.GET_USEFULINFO_SUCCESS,
      payload: respone
     
    } );
  } catch (error) {
    
    yield put({
      type: types.GET_USEFULINFO_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
function*getListType(action) {
  try {
    const respone = yield getType(action.payload);
    yield put({
      
      type: types.GET_LISTTYPE_SUCCESS,
      payload: respone
     
    } );
  } catch (error) {
    
    yield put({
      type: types.GET_LISTTYPE_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const UsefulInfoSaga = [
  takeEvery(types.GET_USEFULINFO_REQUEST, getUsefulInfo),
  takeEvery(types.GET_LISTTYPE_REQUEST, getListType)
];