import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "reactjs-dropdown-component";
import history from "../../history";
import {
	convertVietnameseToEnglishString,
	getLastRouteFromUrl,
} from "../../utils/common";
library.add(faChevronLeft, faChevronRight);

class Usas extends Component {
	state = {
		id: "",
		page: 1,
		topic: [],
		typeObjs: "",
		totalPage: "",
		topicAddView: "Tất cả",
	};

	componentDidMount() {
		// Load posts depending on the topic that is got from url
		let topic = getLastRouteFromUrl();

		if (topic !== "usas") {
			this.props.getData({ page: 1, topic: topic });
			this.setState({
				topicAddView: topic,
			});
		} else {
			this.props.getData({ page: 1, topic: " " });
		}
	}

	componentWillReceiveProps(nextProps) {
		let topic = nextProps.dataCategory.map((item, key) => ({
			id: key + 1,
			title: item.name,
			selected: false,
			key: "topic",
			link: item.unicode_name,
			label: item.name,
			value: item.unicode_name,
		}));
		this.setState({
			topic: [
				{
					id: 0,
					title: "Tất cả",
					selected: false,
					key: "topic",
					label: "Tất cả",
					value: "",
				},
				...topic,
			],
		});
	}

	addView() {
		this.props.getData({
			page: this.state.page + 1,
			topic:
				this.state.topicAddView === "Tất cả" ? " " : this.state.topicAddView,
		});
		this.setState({ page: this.state.page + 1 });
	}

	onTopicChange = (item) => {
		// Call API to reload data with new type
		this.props.getData({ page: 1, topic: item.id === 0 ? " " : item.link });
		this.setState({
			topicAddView: item.label === "Tất cả" ? "Tất cả" : item.link,
			page: 1,
		});
		// Change url
		let topic = item.label === "Tất cả" ? "" : "/" + item.link;
		history.push("/usas" + topic);
	};

	generatePostUrl = (postId, postTitle) => {
		return postId
			? "/beauty/" + convertVietnameseToEnglishString(postTitle) + "-" + postId
			: "usas#";
	};

	render() {
		// Get data
		let dataList = [];
		// let image
		let totalPage;

		if (this.props.totalPage) {
			totalPage = this.props.totalPage;
		}

		if (this.props.listData) {
			// Append domain to every image source
			let posts = this.props.listData.map((post) => {
				// Cannot use positive look behind on safari => Remove lookbehind
				let srcRegex = post.description.match(/(?:src=")[^"]+/);
				let src = "";
				if (srcRegex) {
					src = srcRegex.toString().substring(5); // Remove src="
				}

				return { ...post, description: [{ src: src, alt: "" }] };
			});
			// Append temp items to an uncomplete array
			let numbTempItems = posts.length % 6 === 0 ? 0 : 6 - (posts.length % 6);
			for (let i = 0; i < numbTempItems; i++) {
				posts.push({
					_id: "",
					title: "",
					topic: "",
					description: [{ src: "/images/slide1.jpg", alt: "" }],
				});
			}

			// Generate HTML elements using for loop
			for (let i = 0; i < posts.length; i += 6) {
				dataList.push(
					<div key={i}>
						<div className="row row-2 container-travel">
							<div className="col-md-8 col-left col-img-first">
								<a href={this.generatePostUrl(posts[i]._id, posts[i].title)}>
									<img
										src={posts[i].description[0].src}
										alt={posts[i].description[0].alt}
									/>
									<div className="text-item-usa">
										<h3>{posts[i].title}</h3>
										<p className="bigTitle">{posts[i].topic}</p>
									</div>
									<div className="mask"></div>
								</a>
							</div>
							<div className="col-md-4 col-right">
								<div className="row-top">
									<a
										href={this.generatePostUrl(
											posts[i + 1]._id,
											posts[i + 1].title
										)}
									>
										<img
											width={"100%"}
											height={"100%"}
											src={posts[i + 1].description[0].src}
											alt={posts[i + 1].description[0].alt}
										/>
										<div className="text-item-usa">
											<h4>{posts[i + 1].title}</h4>
											<p className="smallTitle">{posts[i + 1].topic}</p>
										</div>
										<div className="mask"></div>
									</a>
								</div>
								<div className="row-bottom">
									<a
										href={this.generatePostUrl(
											posts[i + 2]._id,
											posts[i + 2].title
										)}
									>
										<img
											width={"100%"}
											height={"100%"}
											src={posts[i + 2].description[0].src}
											alt={posts[i + 2].description[0].alt}
										/>
										<div className="text-item-usa">
											<h4>{posts[i + 2].title}</h4>
											<p className="smallTitle">{posts[i + 2].topic}</p>
										</div>
										<div className="mask"></div>
									</a>
								</div>
							</div>
						</div>
						<div className="row row-2 container-travel">
							<div className="col-md-4 col-left small-img">
								<div className="row-top">
									<a
										href={this.generatePostUrl(
											posts[i + 3]._id,
											posts[i + 3].title
										)}
									>
										<img
											width={"100%"}
											height={"100%"}
											src={posts[i + 3].description[0].src}
											alt={posts[i + 3].description[0].alt}
										/>
										<div className="text-item-usa">
											<h4>{posts[i + 3].title}</h4>
											<p className="smallTitle">{posts[i + 3].topic}</p>
										</div>
										<div className="mask"></div>
									</a>
								</div>
								<div className="row-bottom">
									<a
										href={this.generatePostUrl(
											posts[i + 4]._id,
											posts[i + 4].title
										)}
									>
										<img
											width={"100%"}
											height={"100%"}
											src={posts[i + 4].description[0].src}
											alt={posts[i + 4].description[0].alt}
										/>
										<div className="text-item-usa">
											<h4>{posts[i + 4].title}</h4>
											<p className="smallTitle">{posts[i + 4].topic}</p>
										</div>
										<div className="mask"></div>
									</a>
								</div>
							</div>
							<div
								className="col-md-8 col-right col-img-last"
								style={{ paddingRight: "5px" }}
							>
								<a
									href={this.generatePostUrl(
										posts[i + 5]._id,
										posts[i + 5].title
									)}
								>
									<img
										width={"100%"}
										height={"100%"}
										src={posts[i + 5].description[0].src}
										alt={posts[i + 5].description[0].alt}
									/>
									<div className="text-item-usa">
										<h3>{posts[i + 5].title}</h3>
										<p className="bigTitle">{posts[i + 5].topic}</p>
									</div>
									<div className="mask1"></div>
								</a>
							</div>
						</div>
					</div>
				);
			}
		}

		// Get category
		return (
			<div style={{ marginBottom: 10 }}>
				<div className="wrap-usas">
					<div className="image-head-event">
						<div className="container-travel">
							<div className="row row-3">
								<div className="col-md-8 wrap-page-intro-block">
									<h1>VẺ ĐẸP MỸ</h1>
								</div>
								<div className="col-md-4">
									<Dropdown
										title="CHỦ ĐỀ"
										list={this.state.topic}
										onChange={this.onTopicChange}
										styles={{
											checkIcon: { display: "none" },
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					{dataList}
					{totalPage !== this.state.page && totalPage !== 0 && (
						<div className="col-12 text-center wrap-btn-support btn-see-more-usas">
							<button
								className="btn-contact btn btn-danger btn-more-home"
								style={{ color: "white" }}
								onClick={() => {
									this.addView();
								}}
							>
								<span>Xem thêm</span>
								<i className="icon-arrow-btn">→</i>
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Usas;
