import React from 'react'

export default class PostContent extends React.Component {
    shouldComponentUpdate = (nextProps) => {
        const changed = nextProps.content !== this.props.content
        if(changed) {
            window.tinymce.DOM.setHTML('wrap-editor', nextProps.content)
        }
        return changed
    }

    render = () => {
        return (
            <div id="wrap-editor"></div>
        )
    }
}