import { put, takeEvery } from 'redux-saga/effects';
import getDataDB from '../fetchAPI/Highlightfetch';
import * as types from '../constant';

function* getDataDefault() {
    try {
        //log
        const res = yield getDataDB();
        yield put({
            type: types.GET_DATA_HIGHLIGHT_SUCCESS,
            payload: res
        });
    }
    catch (error) {
        yield put({
            type: types.GET_IMAGE_EVENT_FAILURE,
            payload: {
                errorMessage: error.message
            }
        });
    }
}
export const HighlightTourDataSaga = [
    takeEvery( types.GET_DATA_HIGHLIGHT_REQUEST, getDataDefault)
];
