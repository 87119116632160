import * as types from '../constant';
export function getUseInfo(payload){
  return ({
    type: types.GET_USEFULINFO_REQUEST,
    payload
  });
}
export function getTypeList(payload){
  return ({
    type: types.GET_LISTTYPE_REQUEST,
    payload
  });
}