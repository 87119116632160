import * as types from "../constant"
export function addPriceRequest(payload){
    return({
        type: types.ADD_PRICEREQUEST_REQUEST,
        payload
    })
}
export function resetAction(){
    return {
        type: types.PREPARE_NEW_PRICEREQUEST
    }
    
}
