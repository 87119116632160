import React from 'react'
import "../styles/home.css"
import ImageSlideContainer from '../containers/homePage/ImageSlideContainer'
import TourScheduleContainer from '../containers/homePage/TourScheduleContainer'
import NewEventsContainer from '../containers/homePage/NewEventsContainer'


class HomePage extends React.Component {
  render() {
    return (
      <div className="HomePage">
        <ImageSlideContainer />
        <TourScheduleContainer />
        <NewEventsContainer />
       </div>
    );
  }
}

export default HomePage;