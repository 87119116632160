import React from 'react'
import { Checkbox } from 'semantic-ui-react'
// import { Row } from ''

export default class CustomCheckbox extends React.Component {

    state = {
        label: this.props.label || "Chọn",
        selectedValue: this.props.defaultValue || ""
    }

    handleChange = (event, { value }) => {
        this.setState({ selectedValue: value })
        this.props.handleChange(value)
    }

    render = () => {
        let checkboxes = this.props.values.map((item, idx) => {
            return (
                <Checkbox
                    key={idx}
                    radio name='checkboxRadioGroup'
                    label={item}
                    value={item}
                    checked={this.state.selectedValue === item}
                    onChange={this.handleChange}
                />
            )
        })
        return (
            <div className="wrap-checkbox">
                <div className="title-input-price checkbox-label">{this.state.label + ":"}</div>
                {checkboxes}
            </div>
        )
    }
}