import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/UsefulInfoActions'
import UsefulInfo from '../../components/usefulInfoPage/Usefullinfo'
import { getIdFromUrl } from '../../utils/common'

class UsefulInfoContainer extends Component {
    componentDidMount() {        
        this.props.getTypeList()
        const id = getIdFromUrl()
        this.props.getUsefulInfo({id})
      }
    render() {
        return (
            <UsefulInfo {...this.props}/>
        );
    }
}
const mapStateToProps = (store) => {
    return {
        dataUsefulInfo: store.useful.usefulInfo,
        dataTypeList: store.listType.listType,
        isLoadingData:store.useful.isFetching,
        isLoading:store.listType.isFetching,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUsefulInfo: (data) => {
            dispatch(actions.getUseInfo(data))
        },
        getTypeList: (data) => {
            dispatch(actions.getTypeList(data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsefulInfoContainer)