import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../../styles/silde.css'
class Slide extends Component {
    render() {
        let slideList
        if (this.props.dataTourInfo) {
            slideList = this.props.dataTourInfo.slides.map((item, key) => {
                return (
                    <div className="image-Slide-Tour" key={key}>
                        <img src={item.src} alt={item.alt} />
                    </div>
                )
            })
        }
        return (
            <div className="wrap-tour-slide">
                <Carousel
                    
                    infiniteLoop={true}
                    emulateTouch={true}
                    showIndicators={true}
                    showArrows={false}
                    //autoPlay={true} 
                    className="showArrow"
                >
                    {slideList}
                </Carousel>
            </div>
        );
    }
}
export default Slide;
