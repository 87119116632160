import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import * as EmailValidator from 'email-validator'
import NotificationModal from '../common/NotificationModal'
import ErrorModal from '../common/ErrorModal'
import { Dropdown, Input } from 'semantic-ui-react'
import CustomCheckbox from './CustomCheckbox'
import { OTHER_TOUR_TYPE, FRONT_END_URL } from '../../constant'
import { getTomorrowFormatedDate } from '../../utils/common'

const selfHostText = "Du khách/ Cá nhân"

class PriceRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableBtn: false,
            ...this.getDefaultState()
        }
    }

    componentDidMount() {
        // Get previous order selected
        if (localStorage.getItem('orderSelectedId')) {
            this.setState({
                tourType: localStorage.getItem('orderSelectedType'),
                schedule: 
                    localStorage.getItem('orderSelectedName') 
                    + " - " 
                    + FRONT_END_URL 
                    + localStorage.getItem('orderSelectedId'),
                minDate: localStorage.getItem('orderSelectedTime') || getTomorrowFormatedDate(),
                expDate: localStorage.getItem('orderSelectedTime')
            });
        // }, () => localStorage.clear());
            // Clear storage after got order information
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAdded) {
            this.modal.open()
            this.setState(this.getDefaultState(), this.props.resetAction());
        } else if (!nextProps.isAdded && !nextProps.isSending && this.state !== this.getDefaultState()) {
            //alert("Có lỗi xảy ra! Vui lòng thử lại sau vài phút");
        }
    }

    getDefaultState() {
        return {
            schedule: "",
            canSpeakVie: false,
            minDate: getTomorrowFormatedDate(),
            expDate: getTomorrowFormatedDate(),
            numOfPeople: 1,
            hasTourGui: false,
            name: "",
            email: "",
            phoneNum: "",
            company: "",
            note: "",
            placeholder: "",
            num: "",
            scheduleIsValid: false,
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            numIsValid: false,
            dateIsValid: false,
            firstTime: true,
            other_contact: "",
            isSelfHost: true,
            market: "",
            position: "",
            license: "",
            tourType: "",
        }
    }

    onChange = (value) => {
        this.setState({ enableBtn: !!value })
    }

    checkEmailAndPhone() {
        let validEmail = EmailValidator.validate(this.state.email)
        // let validPhone = PhoneValidator.validate(this.state.phoneNum).isValidate
        let validPhone = this.state.phoneNum.match(/^\+?[0-9 ]{10,}$/)
        this.setState({
            emailIsValid: validEmail,
            phoneIsValid: validPhone
        })
        return validEmail && validPhone
    }

    showErrorModal = () => {
        const validEmail = EmailValidator.validate(this.state.email)
        const validPhone = this.state.phoneNum.match(/^\+?[0-9 ]{10,}$/)
        const validName = !!this.state.name.trim()
        const validSchedule = !!this.state.schedule.trim()

        let err = "Bạn cần nhập "
        if(!validEmail)
            err += "email"
        if(!validPhone) {
            if (!validEmail) err += ", "
            err += "số điện thoại"
        }
        if(!validEmail || !validPhone)
            err += " hợp lệ"
        if(!validSchedule || !validName) {
            if(!validEmail || !validPhone) err += ", "
            err += "đầy đủ "
        }
        if(!validSchedule)
            err += "lịch trình"
        if(!validName) {
            if(!validSchedule) err += ", "
            err += "họ tên"
        }
        // if(!validNote){
        //     if(!validSchedule || !validName) err += ", "
        //     err += "yêu cầu"
        // }
        err += "."

        this.errorModal.open(err)
    }

    validate = () => {
        return this.state.isSelfHost
            ? 
                this.state.tourType.trim() && this.state.schedule.trim() && this.state.name.trim()
                && this.checkEmailAndPhone() && this.state.expDate
            : 
                this.state.tourType.trim() && this.state.schedule.trim() && this.state.name.trim()
                && this.checkEmailAndPhone() && this.state.expDate && this.state.company.trim()
                && this.state.market.trim() && this.state.position.trim()
    }

    submit = () => {
        if (this.validate()) {
            this.submitData()
        }
        else {
            this.showErrorModal()
            this.setState({
                placeholder: "Mời nhập thông tin vào đây",
                num: "Nhập số người",
                scheduleIsValid: !!this.state.schedule.trim(),
                nameIsValid: !!this.state.name.trim(),
                numIsValid: !!this.state.numOfPeople,
                dateIsValid: !!this.state.expDate.trim(),
                firstTime: false
            })
        }
    }

    submitData() {
        this.props.sendRequest(this.state)
    }

    render() {
        let title = "", content = ""
        if (this.props.texts.bodyText) {
            title = this.props.texts.bodyText.price_request.text.title
            content = this.props.texts.bodyText.price_request.text.content
        }
        return (
            <div className="wrap-price">
                <div className="container-travel container-price">
                    <div className="desc-title wrap-page-intro-block">
                        <h1 className="title-center title-top-price">{title}</h1>
                        <p
                            className="desc-contact desc-contact-price auto-next-line-p"
                        >
                            {content}
                        </p>
                    </div>
                    <div className="form-contact form-request-price">

                        {/* <div className="desc-contact desc-contact-price" style={{ margin: 0, textAlign: 'left', paddingLeft: 15 }}>
                            <i>(&quot;Trường cần thông tin&quot;)</i>
                        </div> */}
                        <div 
                            className="title-form title-input-price"
                            style={{ paddingLeft: 15 }}>
                            Chủ đề tours
                        </div>
                        <div className="row">
                            <div className="col-md-6">

                                <Dropdown
                                    className="custom-semantic-dropdown"
                                    placeholder='Chọn chủ đề tours'
                                    value={this.state.tourType}
                                    fluid search selection
                                    options={this.props.tourCategory.map(item => ({ key: item.type, text: item.type, value: item.type })) }
                                    onChange={(evt, { value }) => {
                                        this.props.selectTourType(value)
                                        this.setState({ tourType: value, schedule: "" })
                                    }}
                                />

                                <div className="title-form title-input-price">Lịch trình/ điểm đến (*)</div>
                                {/* <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.scheduleIsValid) ? "" : "classValidate")} type="text"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            schedule: e.target.value,
                                            scheduleIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.schedule}
                                /> */}
                                {
                                    this.state.tourType === OTHER_TOUR_TYPE 
                                    ? 
                                        <Input 
                                            placeholder='Chọn/ ghi xuống đây lịch trình của bạn'
                                            className="custom-semantic-dropdown" fluid
                                            onChange={(evt, {value}) => {
                                                this.setState({ schedule: value, scheduleIsValid: !!value.trim() })
                                            }}
                                        />
                                    :
                                        <Dropdown
                                            className="custom-semantic-dropdown"
                                            placeholder='Chọn/ ghi xuống đây lịch trình của bạn'
                                            value={this.state.schedule}
                                            fluid search selection
                                            options={this.props.tourByType.map(item => ({ 
                                                key: item.id, text: item.name, value: item.name + " - " + FRONT_END_URL + item.id  
                                            })) }
                                            onChange={(evt, {value}) => {
                                                this.setState({ schedule: value, scheduleIsValid: !!value.trim() })
                                            }}
                                        />
                                }
                                <div className="row">
                                    <div className="col-8 no-padding">
                                        <div className="title-form title-input-price">Ngày khởi hành</div>
                                    </div>
                                    <div className="col-4 no-padding-right">
                                        <div className="title-form title-input-price">Số người</div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8 no-padding">
                                        <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.dateIsValid) ? "" : "classValidate")} type="date"
                                            min={this.state.minDate}
                                            placeholder={this.state.placeholder}
                                            onChange={(e) => {
                                                this.setState({
                                                    expDate: e.target.value,
                                                    dateIsValid: !!e.target.value.trim()
                                                })
                                            }}
                                            value={this.state.expDate}
                                        />
                                    </div>
                                    <div className="col-4 no-padding-right">
                                        <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.numIsValid) ? "" : "classValidate")} type="number"
                                            placeholder={this.state.num}
                                            value={this.state.numOfPeople}
                                            min="1"
                                            max="200"
                                            onChange={e => this.setState({ numOfPeople: e.target.value, numIsValid: !!e.target.value.trim() })}/>
                                    </div>
                                </div>
                                <div className="title-form title-input-price">
                                    Công ty / website nơi bạn làm việc
                                    {!this.state.isSelfHost && " (*)"}
                                </div>
                                <input className="form-control form-input-price" type="text"
                                    onChange={(e) => {
                                        this.setState({
                                            company: e.target.value
                                        })
                                    }}
                                    value={this.state.company}
                                />
                                {
                                    !this.state.isSelfHost
                                    ? 
                                    <>
                                        <div className="title-form title-input-price">Thị trường chính (*)</div>
                                        <input className="form-control form-input-price" type="text"
                                            onChange={(e) => {
                                                this.setState({
                                                    market: e.target.value
                                                })
                                            }}
                                            value={this.state.market}
                                        />
                                        <div className="title-form title-input-price">Giấy phép LHQT (hoặc PATA, IATA)</div>
                                        <input className="form-control form-input-price" type="text"
                                            onChange={(e) => {
                                                this.setState({
                                                    license: e.target.value
                                                })
                                            }}
                                            value={this.state.license}
                                        />
                                    </>
                                    : null
                                }
                            </div>
                            {/* <div className="col-md-2 space-col-price"> */}
                            {/* </div> */}
                            <div className="col-md-6">
                                
                                <CustomCheckbox 
                                    label="Bạn là"
                                    defaultValue={selfHostText}
                                    values={[selfHostText, "Công ty Du lịch"]}
                                    handleChange={value => this.setState({ isSelfHost: value === selfHostText })}
                                />

                                <div className="title-form title-input-price">Họ tên của bạn (*)</div>
                                {/* <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.nameIsValid) ? "" : "classValidate")} type="text"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            name: e.target.value,
                                            nameIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.name}

                                /> */}
                                <Input 
                                    className="custom-semantic-dropdown" fluid placeholder={this.state.placeholder} 
                                    value={this.state.name}
                                    onChange={(evt, {value}) => this.setState({
                                            name: value,
                                            nameIsValid: !!value.trim()
                                        })}/>
                                <div className="title-form title-input-price">Địa chỉ email (*)</div>
                                <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.emailIsValid)
                                    ? "" : "classValidate")} type="text"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            email: e.target.value,
                                            emailIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.email}
                                />
                                <div className="title-form title-input-price">Số điện thoại (*)</div>
                                <input className={"form-control form-input-price " + ((this.state.firstTime || this.state.phoneIsValid)
                                    ? "" : "classValidate")} type="text"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            phoneNum: e.target.value,
                                            phoneIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.phoneNum}
                                />
                                {
                                    !this.state.isSelfHost
                                    &&
                                    <>
                                        <div className="title-form title-input-price">Chức vụ/ vị trí làm việc của bạn (*)</div>
                                        <input className="form-control form-input-price" type="text"
                                            onChange={e => this.setState({ position: e.target.value })}
                                            value={this.state.position}
                                        />
                                        <div className="title-form title-input-price">Phương thức liên lạc khác</div>
                                        <input className="form-control form-input-price" type="text"
                                            placeholder={this.state.placeholder || "Facebook, Zalo, Viber, ..."}
                                            onChange={(e) => this.setState({ other_contact: e.target.value })}
                                            value={this.state.other_contact}
                                        />
                                    </> 
                                }
                            </div>
                            {
                                this.state.isSelfHost
                                &&
                                <div className="col-12">
                                    <div className="title-form title-input-price">Phương thức liên lạc khác</div>
                                    <input
                                        className="form-control form-input-price"
                                        style={{ width: "100%" }}
                                        placeholder={this.state.placeholder || "Facebook, Zalo, Viber, ..."}
                                        onChange={(e) => this.setState({ other_contact: e.target.value })}
                                        value={this.state.other_contact}
                                    />
                                </div>
                            }
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <NotificationModal ref={ref => this.modal = ref} />
                            </div>
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <ErrorModal ref={ref => this.errorModal = ref} />
                            </div>
                            <div className="col-12">
                                <div className="title-form title-input-price">Yêu cầu riêng</div>
                                <textarea className={"form-control form-input-price"}
                                    style={{ width: "100%", resize: "none" }}
                                    placeholder={"Ghi xuống đây các yêu cầu riêng của bạn phục vụ cho chuyến đi (ví dụ: các yêu cầu về bữa ăn, vị trí, khách sạn,...) "}
                                    onChange={(e) => {
                                        this.setState({
                                            note: e.target.value,
                                            // noteIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.note}
                                ></textarea>
                                <div className="title-form title-input-price text-center">Xác nhận</div>
                                <div className="text-center">
                                    <ReCAPTCHA
                                        className="captcha"
                                        hl="vi"
                                        sitekey="6LdL57QUAAAAAPOEyIFrjGYeChKMa7bV8M-lzqsM"
                                        onChange={this.onChange}
                                    />
                                    {/* Disable button Send if we are already sending an request */}
                                    {this.props.isSending
                                        ? <div>Đang xử lý yêu cầu! Vui lòng chờ giây lát ...</div>
                                        : <button disabled={!this.state.enableBtn} style={{ color: "white", fontWeight: "bold" }}
                                            className={
                                                this.state.enableBtn
                                                    ? "btn-contact btn btn-danger btn-send btn-more-home btn-price-request"
                                                    : "btn-contact btn btn-danger btn-send"
                                            }
                                            onClick={this.submit}>
                                            <span>GỬI YÊU CẦU</span>
                                            <i className="icon-arrow-btn">→</i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default PriceRequest