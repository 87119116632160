import React from "react";
import { TEXT_SEARCH_STORAGE_KEY } from "../../constant";
// import { Button } from 'reactstrap'
// import { Modal, Header } from 'semantic-ui-react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class GoogleCustomSearchInput extends React.Component {
	state = {
		keyword: "",
	};

	handlePressKey = (e) => {
		const ENTER_KEYCODE = 13;
		const keyword = this.state.keyword;
		// Check keycode that user press
		if (e.keyCode === ENTER_KEYCODE && keyword) {
			window.location.href = "/search?q=" + keyword + "&page=1";
		}
	};

	closeResultModal = () => {
		// Clear input
		this.gcsInput.value = "";
		// Dispatch action close modal
		this.props.closeResultModal();
		// Reset state
		this.setState({
			keyword: "",
		});
	};

	handleSearch = () => {
		const { keyword } = this.state;
		// this.props.callGoogleCustomSearch(keyword)
		if (keyword) {
			localStorage.setItem(TEXT_SEARCH_STORAGE_KEY, keyword);
			window.location.href = "/search?q=" + keyword;
		}
	};

	render = () => {
		// Initialize params
		// let googleItems, totalResult = 0, searchTime = 0
		// if(this.props.googleObj) {
		//     // Map result item to React component
		//     googleItems = this.props.googleObj.items.map(
		//         (item, idx) => <GoogleResultItem key={idx} item={item}/>
		//     )
		//     totalResult = this.props.googleObj.totalResult
		//     searchTime = this.props.googleObj.searchTime
		// }

		return (
			<div className="wrap-gcs">
				<input
					ref={(ref) => (this.gcsInput = ref)}
					placeholder="Tìm kiếm tours, thông tin du lịch Mỹ..."
					onKeyDown={this.handlePressKey}
					onChange={(e) => this.setState({ keyword: e.target.value })}
				/>

				<div className="wrap-icon-search" onClick={this.handleSearch}>
					<img
						src={"/images/search.png"}
						className="icon-search"
						alt="icon-search"
					/>
				</div>

				{/* 
                <Modal 
                    className="gcs-result-modal"
                    closeIcon
                    open={this.props.isOpenResultModal}
                    onClose={this.closeResultModal}>
                        <Header 
                            className="gcs-header-text"
                            size="tiny" 
                            content={`Tìm được ${totalResult} kết quả trong ${searchTime} giây`}
                        />
                        <Modal.Content scrolling>
                            {
                                googleItems && googleItems.length
                                ? googleItems
                                : <p>{"Không tìm thấy kết quả nào"}</p>
                            }
                        </Modal.Content>
                </Modal> 
                */}
			</div>
		);
	};
}

// class GoogleResultItem extends React.PureComponent {
//     render = () => {
//         const {
//             title, link, description, imgSrc
//         } = this.props.item
//         return (
//             <div className="wrap-gcs-item">
//                 <a
//                     className="gcs-item-a"
//                     href={link}>
//                     {title}
//                 </a>
//                 <p className="gcs-item-link">{link}</p>
//                 <div className="gcs-item-wrap-desc">
//                     {imgSrc &&
//                         <a href={link}>
//                             <img
//                                 className="gcs-item-img"
//                                 src={imgSrc}
//                                 alt="nuocmy.net"
//                             />
//                         </a>
//                     }
//                     <p className="gcs-item-desc">{description}</p>
//                 </div>
//             </div>
//         )
//     }
// }
