import { put, takeEvery } from 'redux-saga/effects';
import getEvent from '../fetchAPI/getEventInfo'
import EventNew from '../fetchAPI/getEventNew'
import * as types from '../constant';
function* getEventInfo(action) {
  try {
    const res = yield getEvent(action.payload);
    yield put({
      type: types.GET_EVENTINFO_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_EVENTINFO_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}
function* getEventNew(action) {
    try {
      const res = yield EventNew(action.payload);
      yield put({
        type: types.GET_EVENTNEW_SUCCESS,
        payload: res
      });
    } catch (error) {
      yield put({
        type: types.GET_EVENTNEW_FAILURE,
        payload: {
          errorMessage: error.message
        }
      });
    }
  }

export const EventInfoSaga = [
  takeEvery(types.GET_EVENTINFO_REQUEST, getEventInfo),
  takeEvery(types.GET_EVENTNEW_REQUEST, getEventNew)
  
];