import * as types from '../../constant';
const DEFAULT_STATE = {
  listUsaData: [],
  listUsaDataSwap:[],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  // activePage: 1,
  totalPage: null
};


export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_LIST_USA_REQUEST:
      return {
        ...state,
        isFetching: true,
        // activePage: action.payload.page

      };
    case types.GET_LIST_USA_SUCCESS:
    let newListPost = action.payload.page === 1 
    ? action.payload.posts 
    : [...state.listUsaData, ...action.payload.posts];
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage:null,
        listUsaData: newListPost,
        listUsaDataSwap:action.payload.posts,
        totalPage: action.payload.total_pages
        
      };
    case types.GET_LIST_USA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};