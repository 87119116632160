import * as types from '../constant';
import { filterTourByType } from '../utils/common';
const DEFAULT_STATE = {
  listCategoryTour: [],
  activeListTourByType: [],
  listTours: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_TOUR_FOLLOW_TYPE_REQUEST:
    case types.GET_TOURCATEGORY_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SELECT_TOUR_TYPE: 
      return {
        ...state,
        activeListTourByType: filterTourByType(state.listTours, action.payload)
      }
    case types.GET_TOUR_FOLLOW_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: true,
        listTours: action.payload.tour_types,
        activeListTourByType: 
          localStorage.getItem("orderSelectedType") 
          ? filterTourByType(action.payload.tour_types, localStorage.getItem("orderSelectedType"))
          : []
      }
    case types.GET_TOURCATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage:null,
        listCategoryTour: action.payload.types,
      };
    case types.GET_TOURCATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};