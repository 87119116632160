import React, { Component } from 'react';
import history from '../../history'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { convertVietnameseToEnglishString } from '../../utils/common'
library.add(faPhone)

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            topic: [],
            postLinks: "",
            typeObjs: []
        }
    }

    componentDidMount() {
        let fixedTypes = ["nước Mỹ", "Thông tin"]
        this.setState({
            topic: fixedTypes.map((item, key) => ({
                id: key + 1, title: item
            }))
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dataTypeList) {
            let tempArr = []
            for (let i = 0; i < nextProps.dataTypeList.length; i++) {
                if (!nextProps.dataTypeList[i])
                    continue
                let type = nextProps.dataTypeList[i].type
                if (type === "US-Useful") {
                    tempArr[1] = nextProps.dataTypeList[i]
                } else if (type === "US-Info") {
                    tempArr[0] = nextProps.dataTypeList[i]
                }
            }
            this.setState({ typeObjs: tempArr })
        }
    }
    changePost(postId) {
        this.props.getUsefulInfo({ id: postId })
    }
    render() {
        let listType
        let listPost = [[], [], []]
        if (this.state.typeObjs) {
            this.state.typeObjs.forEach((postType, key) => {
                listPost[key] = postType.posts.map((post, idx) => {
                    return (
                        <li onClick={() => this.changePost(post.id)} key={idx}>
                            <a href={"/post/" + convertVietnameseToEnglishString(post.title) + "-" + post._id}>{post.title}</a>
                        </li>
                    )
                })
            })
        }
        if (this.state.topic) {
            listType = this.state.topic.map((item, key) => {
                return (
                    <div key={key} className="col-lg-3 col-md-6">
                        <ul>
                            <li className="head-title-footer">{item.title.toUpperCase()}</li>
                            {listPost[key]}
                        </ul>
                    </div>
                )
            })
        }
        let tel, email // zalo, whatsapp, viber
        if (this.props.texts.contact) {
            tel = this.props.texts.contact.tel;
            email = this.props.texts.contact.email;
            // zalo = this.props.texts.contact.zalo;
            // whatsapp = this.props.texts.contact.whatsapp;
            // viber = this.props.texts.contact.viber;
        }
        let title = "", content = ""
        if(this.props.texts.bodyText) {
            title = this.props.texts.bodyText.footer.text.title
            content = this.props.texts.bodyText.footer.text.content
        }

        return (
            <div className="footer">
                {(history.location.pathname === "/contact" || history.location.pathname === "/price-request")
                    ? "" :
                    <div className="wrap-support">
                        <div className="container-travel">
                            <div className="row wrap-page-intro-block text-center">
                                        <h1 style={{ margin: "0 auto"}}>{title}</h1>
                                        <p 
                                            className="text-center auto-next-line-p" 
                                            style={{ paddingBottom: "calc(100vw*0.013947)"}}>
                                            {content}
                                        </p>
                                </div>
                                {/* <div className="col-md-3 col-12 col-sm-12 text-center wrap-btn-support" > */}
                                <div className="text-center wrap-btn-support" >
                                    {/* <a target="nuocmy_sub_frame" href="/contact" className="btn-contact btn btn-danger btn-more-home btn-contact-footer"> */}
                                    <a target="nuocmy_sub_frame" href="/contact" className="btn-contact btn btn-danger btn-more-home">
                                        <span style={{ color: "white", fontWeight: "bold" }}>Liên hệ</span>
                                        <i style={{ color: "white", fontWeight: "bold" }} className="icon-arrow-btn">→</i>
                                    </a>
                                </div>
                        </div>
                    </div>
                }
                <div className="container-travel wrap-link-footer">
                    <div className="row">
                        {listType}

                        <div className="col-lg-3 col-md-6">
                            <ul>
                                <li className="head-title-footer">VỀ CHÚNG TÔI</li>
                                <li ><a href="/about-us">Giới thiệu</a></li>
                                <li ><a href="/faq">FAQs</a></li>
                                <li ><a href="/terms">Điều khoản sử dụng</a></li>
                                <li ><a href="/highlight-tours">Tours đã thực hiện</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-last" style={{ textAlign: 'center' }}>
                            <div className="wrap-logo">
                                <img className="logo-footer" src="/images/logo.png" alt="nuocmy.net" />
                            </div>
                            <div className="email-company-footer">{email}</div>
                            <div className="phone-footer-number"><span>{tel}</span></div>
                            {/* <div className="social-footer"></div>
                            <div style={{margin:"0 auto"}}>
                                <div className="row row-contact">
                                    <a
                                        href={"tel:" + tel}>
                                        <img className="phone-footer" src={'/images/phone.png'} alt=""></img>
                                    </a>
                                    <a
                                        href={"http://zalo.me/" + zalo}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img className="zalo-footer" src={'/images/zalo.png'} alt=""></img>
                                    </a>
                                    <a
                                        href={"https://wa.me/" + whatsapp}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img className="whatsapp-footer" src={'/images/whatsapp.png'} alt=""></img>
                                    </a>
                                    <a
                                        href={"https://viber.me/" + viber}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img className="viber-footer" src={'/images/viber.png'} alt=""></img>
                                    </a>
                                </div>
                                <div className="phone-footer-number"><span>{tel}</span></div> 
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
