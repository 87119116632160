import React, { Component } from 'react';
import { connect } from 'react-redux'
import More from '../../components/tourInfoPage/More'
import * as actions from '../../actions/ListTourInfoActions'
class MoreContainer extends Component {
    componentDidMount() {
        this.props.getMore();
      }
    render() {
        return (
            <More {...this.props} />
        );
    }

}

const mapStateToProps = (state) => {
    return {
      dataTourMore:state.tourMore.tourMore
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
      getMore: (data) => {
        dispatch(actions.getTourMore(data));
      }
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(MoreContainer);
// export default MoreContainer;
