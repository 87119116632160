import {domain}  from '../constant'
export default function callApi(activePage) {
    return new Promise((resolve, reject) => {
      let url = domain + "get-available-events/8/"+ activePage;
      fetch(url, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }