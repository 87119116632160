import { put, takeEvery } from 'redux-saga/effects';
import get from '../../fetchAPI/AboutPage/get';
import getIntroduction from '../../fetchAPI/AboutPage/getIntro'
import * as types from '../../constant';
function* getListAbout() {
  try {
    const res = yield get();
    yield put({
      type: types.GET_LIST_ABOUT_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_LIST_ABOUT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

function* getIntro() {
  try {
    const res = yield getIntroduction();
    yield put({
      type: types.GET_INTRO_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_INTRO_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}


export const getAbout = [
  takeEvery(types.GET_LIST_ABOUT_REQUEST, getListAbout),
  takeEvery(types.GET_INTRO_REQUEST, getIntro)
];
