//Header
export const GET_NEW_EVENTS_HEADER_REQUEST = "GET_NEW_EVENTS_HEADER_REQUEST";
export const GET_NEW_EVENTS_HEADER_SUCCESS = "GET_NEW_EVENTS_HEADER_SUCCESS";
export const GET_NEW_EVENTS_HEADER_FAILED = "GET_NEW_EVENTS_HEADER_FAILED";

export const GET_NEW_POSTS_HEADER_REQUEST = "GET_NEW_POSTS_HEADER_REQUEST";
export const GET_NEW_POSTS_HEADER_FAILURE = "GET_NEW_POSTS_HEADER_FAILURE";
export const GET_NEW_POSTS_HEADER_SUCCESS = "GET_NEW_POSTS_HEADER_SUCCESS";

export const GET_ITEM_REQUEST = 'GET_ITEM_REQUEST';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';


//ListTour

export const GET_TOURLIST_REQUEST = 'GET_TOURLIST_REQUEST';
export const GET_TOURLIST_SUCCESS = 'GET_TOURLIST_SUCCESS';
export const GET_TOURLIST_FAILURE = 'GET_TOURLIST_FAILURE';


//CategoryTour

export const GET_TOURCATEGORY_REQUEST = 'GET_TOURCATEGORY_REQUEST';
export const GET_TOURCATEGORY_SUCCESS = 'GET_TOURCATEGORY_SUCCESS';
export const GET_TOURCATEGORY_FAILURE = 'GET_TOURCATEGORY_FAILURE';

//Tours By All Type
export const GET_TOUR_FOLLOW_TYPE_REQUEST = 'GET_TOUR_FOLLOW_TYPE_REQUEST';
export const GET_TOUR_FOLLOW_TYPE_SUCCESS = 'GET_TOUR_FOLLOW_TYPE_SUCCESS';
export const GET_TOUR_FOLLOW_TYPE_FAILURE = 'GET_TOUR_FOLLOW_TYPE_FAILURE';
export const SELECT_TOUR_TYPE = "SELECT_TOUR_TYPE";


//TourInfo

export const GET_TOURINFO_REQUEST = 'GET_TOURINFO_REQUEST';
export const GET_TOURINFO_SUCCESS = 'GET_TOURINFO_SUCCESS';
export const GET_TOURINFO_FAILURE = 'GET_TOURINFO_FAILURE';

//TourMore

export const GET_TOURMORE_REQUEST = 'GET_TOURMORE_REQUEST';
export const GET_TOURMORE_SUCCESS = 'GET_TOURMORE_SUCCESS';
export const GET_TOURMORE_FAILURE = 'GET_TOURMORE_FAILURE';

//EventList

export const GET_EVENTLIST_REQUEST = 'GET_EVENTLIST_REQUEST';
export const GET_EVENTLIST_SUCCESS = 'GET_EVENTLIST_SUCCESS';
export const GET_EVENTLIST_FAILURE = 'GET_EVENTLIST_FAILURE';

export const GET_IMAGE_REQUEST = 'GET_IMAGE_REQUEST';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE';

export const GET_TEXT_REQUEST = 'GET_TEXT_REQUEST';
export const GET_TEXT_SUCCESS = 'GET_TEXT_SUCCESS';
export const GET_TEXT_FAILURE = 'GET_TEXT_FAILURE';

export const GET_IMAGE_EVENT_REQUEST = 'GET_IMAGE_EVENT_REQUEST';
export const GET_IMAGE_EVENT_SUCCESS = 'GET_IMAGE_EVENT_SUCCESS';
export const GET_IMAGE_EVENT_FAILURE = 'GET_IMAGE_EVENT_FAILURE';

export const GET_IMAGE_TOUR_REQUEST = 'GET_IMAGE_TOUR_REQUEST';
export const GET_IMAGE_TOUR_SUCCESS = 'GET_IMAGE_TOUR_SUCCESS';
export const GET_IMAGE_TOUR_FAILURE = 'GET_IMAGE_TOUR_FAILURE';

export const TEXT_SEARCH_STORAGE_KEY = 'NUOCMY_TEXT_SEARCH';

//EventInfo

export const GET_EVENTINFO_REQUEST = 'GET_EVENTINFO_REQUEST';
export const GET_EVENTINFO_SUCCESS = 'GET_EVENTINFO_SUCCESS';
export const GET_EVENTINFO_FAILURE = 'GET_EVENTINFO_FAILURE';

//ListType

export const GET_LISTTYPE_REQUEST = 'GET_LISTTYPE_REQUEST';
export const GET_LISTTYPE_SUCCESS = 'GET_LISTTYPE_SUCCESS';
export const GET_LISTTYPE_FAILURE = 'GET_LISTTYPE_FAILURE';

//UsefulInfo

export const GET_USEFULINFO_REQUEST = 'GET_USEFULINFO_REQUEST';
export const GET_USEFULINFO_SUCCESS = 'GET_USEFULINFO_SUCCESS';
export const GET_USEFULINFO_FAILURE = 'GET_USEFULINFO_FAILURE';


//EventNew

export const GET_EVENTNEW_REQUEST = 'GET_EVENTNEW_REQUEST';
export const GET_EVENTNEW_SUCCESS = 'GET_EVENTNEW_SUCCESS';
export const GET_EVENTNEW_FAILURE = 'GET_EVENTNEW_FAILURE';

// FAQ
export const GET_LIST_FAQ_REQUEST = 'GET_LIST_FAQ_REQUEST';
export const GET_LIST_FAQ_SUCCESS = 'GET_LIST_FAQ_SUCCESS';
export const GET_LIST_FAQ_FAILURE = 'GET_LIST_FAQ_FAILURE';
// About
export const GET_LIST_ABOUT_REQUEST = 'GET_LIST_ABOUT_REQUEST';
export const GET_LIST_ABOUT_SUCCESS = 'GET_LIST_ABOUT_SUCCESS';
export const GET_LIST_ABOUT_FAILURE = 'GET_LIST_ABOUT_FAILURE';

//Intro
export const GET_INTRO_REQUEST = 'GET_INTRO_REQUEST';
export const GET_INTRO_SUCCESS = 'GET_INTRO_SUCCESS';
export const GET_INTRO_FAILURE = 'GET_INTRO_FAILURE';

//Term
export const GET_TERM_REQUEST = 'GET_TERM_REQUEST';
export const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS';
export const GET_TERM_FAILURE = 'GET_TERM_FAILURE';
// highlight
export const GET_DATA_HIGHLIGHT_SUCCESS = 'GET_DATA_HIGHLIGHT_SUCCESS';
export const GET_DATA_HIGHLIGHT_FAILURE = 'GET_DATA_HIGHLIGHT_FAILURE';
export const GET_DATA_HIGHLIGHT_REQUEST = 'GET_DATA_HIGHLIGHT_REQUEST';


// PriceRequest
export const ADD_PRICEREQUEST_REQUEST = "ADD_PRICEREQUEST_REQUEST";
export const ADD_PRICEREQUESR_SUCCESS = "ADD_PRICEREQUEST_SUCCESS";
export const ADD_PRICEREQUESR_FAILURE = "ADD_PRICEREQUEST_FAILURE";
export const  PREPARE_NEW_PRICEREQUEST = "PREPARE_NEW_PRICEREQUEST";

 
// ContactRequest
export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILURE = "ADD_CONTACT_FAILURE";
export const PREPARE_NEW_CONTACT = "PREPARE_NEW_CONTACT";
// USA
export const GET_LIST_USA_REQUEST = 'GET_LIST_USA_REQUEST';
export const GET_LIST_USA_SUCCESS = 'GET_LIST_USA_SUCCESS';
export const GET_LIST_USA_FAILURE = 'GET_LIST_USA_FAILURE';

export const GET_CATEGORY_USA_REQUEST = 'GET_CATEGORY_USA_REQUEST';
export const GET_CATEGORY_USA_SUCCESS = 'GET_CATEGORY_USA_SUCCESS';
export const GET_CATEGORY_USA_FAILURE = 'GET_CATEGORY_USA_FAILURE';

// 3rd request
export const CALL_GOOGLE_SEARCH_REQUEST = 'CALL_GOOGLE_SEARCH_REQUEST'
export const CALL_GOOGLE_SEARCH_SUCCESS = 'CALL_GOOGLE_SEARCH_SUCCESS'
export const CALL_GOOGLE_SEARCH_FAILURE = 'CALL_GOOGLE_SEARCH_FAILURE'
export const TURN_OFF_GOOGLE_MODAL = 'TURN_OFF_GOOGLE_MODAL'

export const OTHER_TOUR_TYPE = "Khác"

export const FRONT_END_URL = "https://nuocmy.net/tour-info/"
export const domain = "https://nuocmy.net/api/"
const GOOGLE_API_KEY = "AIzaSyA0oaMGxKid0VK0IQETdo9tor3yI4orJG8"
const GOOGLE_SEARCH_ENGINE_ID = "016941719398994561432:d8grdy0ectq"
export const GOOGLE_CUSTOM_SEARCH_DOMAIN = "https://www.googleapis.com/customsearch/v1/siterestrict"
    + "?cx=" + GOOGLE_SEARCH_ENGINE_ID
    + "&key=" + GOOGLE_API_KEY
    // https://www.googleapis.com/customsearch/v1?cx=016686720601271523975%3Aphoamqiyg24&key=AIzaSyCMP2YjpvtZ_cNPmz7Drv-pXb5p7whsTDI&q=mm
export const WEB_NAME = "Nước Mỹ"
