import React, { Component } from 'react'
import PostSocialBar from '../common/PostSocialBar'
import PostContent from '../common/PostContent'
import { convertVietnameseToEnglishString } from '../../utils/common'
import PostView from '../common/PostView';

class EventInfo extends Component {

    constructor(props) {
        super(props);
        this.state = { content: "" }
    }

    handleOrderClick() {
        if (this.props.dataEventInfo) {
            // Save link and name of event to storage
            // For getting at price request page
            localStorage.setItem(
                'orderSelected',
                this.props.dataEventInfo.name
                + " - "
                + window.location.hostname
                + "/event-info/"
                + convertVietnameseToEnglishString(this.props.dataEventInfo.name)
                + "-"
                + this.props.dataEventInfo._id
            );
        }
    }

    handlePressRequestTour = () => {
        localStorage.clear()
    }

    render() {
        let listEventNew
        let content = ""
        let name, time, location, price, views
        const { dataEventInfo } = this.props
        if (dataEventInfo) {
            let dt = new Date(dataEventInfo.time)
            name = dataEventInfo.name
            time = dt.getDate() + "-" + (dt.getMonth() + 1) + "-" + dt.getFullYear()
            location = dataEventInfo.location
            price = dataEventInfo.price
            content = dataEventInfo.description
            views = dataEventInfo.views
        }
        if (this.props.dataEventNew) {
            listEventNew = this.props.dataEventNew.map((item, key) => {
                return (
                    <div key={key} className="listEventNews">
                        <a
                            href={"/event-info/" + convertVietnameseToEnglishString(item.name) + "-" + item._id}>
                            <div className="wrap-link-info">
                                {item.name}
                            </div>
                        </a >
                    </div>
                )
            })
        }
        return (
            <div className="wrap-event-info" >
                {/* <div className="image-head-event-info" style={this.props.dataEventInfo && {
                    backgroundImage: 'url(' + this.props.dataEventInfo.cover_img + ')'
                }}>
                    <div className="container-travel wrap-text-head">
                        <h1 className="detail-event-page text-up-shadow">SỰ KIỆN</h1>
                        <h2 className="event-name text-up-shadow">
                            {this.props.dataEventInfo && this.props.dataEventInfo.name}
                        </h2>
                    </div>

                </div> */}
                <div className="wrap-event-name">
                    <h3 className="main-type-color bolder-title">
                        SỰ KIỆN MỚI
                    </h3>
                </div>
                <div className="container-travel">
                    <div className="row">
                        <div className={listEventNew && listEventNew.length ? "col-md-8" : "col-md-12"}>

                            <div>
                                <h3 className="bolder-title">{name}</h3>
                                <table>
                                    <tbody>
                                        <tr className="row-event-info">
                                            <td className="post-infor-header-cell">
                                                <h6 className="post-infor-row">
                                                    <i className="post-infor-header">Thời gian: </i>
                                                </h6>
                                            </td>
                                            <td>
                                                <h6 className="post-infor-row post-infor-detail post-time">{time}</h6>
                                            </td>
                                        </tr>
                                        <tr className="row-event-info">
                                            <td className="post-infor-header-cell">
                                                <h6 className="post-infor-row">
                                                    <i className="post-infor-header">Địa điểm: </i>
                                                </h6>
                                            </td>
                                            <td>
                                                <h6 className="post-infor-row post-infor-detail">{location}</h6>
                                            </td>
                                        </tr>
                                        <tr className="row-event-info">
                                            <td className="post-infor-header-cell">
                                                <h6 className="post-infor-row">
                                                    <i className="post-infor-header">Chi phí từ: </i>
                                                </h6>
                                            </td>
                                            <td>
                                                <h6 className="post-infor-row post-price">
                                                    $
                                                    <b>{price ? price.toString().toFormatPrice() : 0}</b>
                                                </h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <PostContent content={content} />
                            <div className="wrap-schedule-footer">
                                {
                                    views &&
                                    <PostView views={views}/>
                                }
                                <PostSocialBar />
                            </div>
                            <div
                                className="text-center wrap-btn-support"
                                style={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: 10 }}>
                                <a
                                    target="nuocmy_sub_frame"
                                    href="/price-request"
                                    className="btn-contact btn btn-danger btn-more-home"
                                    onClick={this.handlePressRequestTour}>
                                    <span style={{ color: "white", fontWeight: "bold" }}>Đặt tour</span>
                                    <i className="icon-arrow-btn" style={{ color: "white", fontWeight: "bold" }}>→</i>
                                </a>
                            </div>
                        </div>
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-4">
                            {/* {this.props.dataEventInfo &&
                                <img
                                    className="col-12"
                                    style={{ padding: 0 }}
                                    src={ this.props.dataEventInfo.cover_img.src}
                                    alt={this.props.dataEventInfo.cover_img.alt}
                                />
                            } */}
                            {listEventNew && listEventNew.length
                                ? <div
                                    className="wrap-new-event-info moreInfoEvent"
                                    style={{
                                        boxShadow: "1px 2px 10px 0px #9c979c",
                                    }}>
                                    <div className="title-new-event">DANH SÁCH</div>
                                    <div className="wrap-relevant-events">
                                        {listEventNew}
                                    </div>
                                </div>
                                : <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventInfo;
