import React, { Component } from 'react';
import TourSchedule from '../../components/homePage/TourSchedule';
import { connect } from 'react-redux';
import * as actionss from '../../actions/HomePage';

class TourScheduleContainer extends Component {
  componentDidMount() {
    this.props.initLoad();
  }

    render() {
        return (
            <TourSchedule {...this.props}/>
        );
    }

}
const mapStateToProps = (state) => {
    return {
      images: state.imageDault.listImage,
      texts : state.texts.listText,
      imageTour: state.imageTour.listImageTour

    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      initLoad: () => {
        dispatch(actionss.getImageTour());
      }
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(TourScheduleContainer);