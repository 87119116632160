import { put, takeEvery } from 'redux-saga/effects';
import getImageEvent from '../../fetchAPI/HomePage/ImageEventAPI';
import * as types from '../../constant';
function* getImage() {
  try {
    const res = yield getImageEvent();
    yield put({
      type: types.GET_IMAGE_EVENT_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_IMAGE_EVENT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const ImageEventSaga = [
  takeEvery(types.GET_IMAGE_EVENT_REQUEST, getImage)
];
