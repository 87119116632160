import * as types from "../constant"

export function getEventInfo(payload){
    return({
        type: types.GET_EVENTINFO_REQUEST,
        payload
    })
};

export function getEventNew(payload){
    return({
        type: types.GET_EVENTNEW_REQUEST,
        payload
    })
};
