import React, { Component } from "react";
import { Dropdown } from "reactjs-dropdown-component";
import PostContent from "../common/PostContent";
import PostSocialBar from "../common/PostSocialBar";
import { convertVietnameseToEnglishString } from "../../utils/common";

class USBeauty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			topic: [],
			postLinks: "",
			typeObjs: "",
			topicName: "",
			page: 1,
			totalPage: "",
			topicCategory: " ",
		};
	}

	// componentDidMount() {
	//     let fixedTypes = ["Thông tin nước Mỹ", "Thông tin hữu ích"]
	//     this.setState({
	//         topic: fixedTypes.map((item, key) => ({
	//             id: key + 1, title: item, selected: false, key: 'topic'
	//         }))
	//     })
	// }
	componentWillReceiveProps(nextProps) {
		if (
			nextProps.dataTypeList &&
			nextProps.dataUsefulInfo &&
			nextProps.listData
		) {
			this.setState({
				topic: nextProps.dataTypeList.map((item, key) => ({
					id: key + 1,
					title: item.name,
					selected: false,
					key: `topic-${key + 1}`,
					link: item.unicode_name,
					value: item.unicode_name,
					label: item.name,
				})),
				topicName: nextProps.dataUsefulInfo.topic,
				postLinks: nextProps.listData,
			});
		}
	}

	// resetThenSet = (id, key) => {
	// 	// Initialize new dropdown with current value that user selected
	// 	let temp = JSON.parse(JSON.stringify(this.state[key]));
	// 	temp.forEach((item) => (item.selected = false));
	// 	temp[id - 1].selected = true;
	// 	this.props.getData({
	// 		page: 1,
	// 		topic: this.state.topic[id - 1].link,
	// 		postId: this.props.activePostId,
	// 		numberOfPosts: 5,
	// 	});
	// 	this.setState({ topicCategory: this.state.topic[id - 1].link, page: 1 });
	// };

	onTopicChange = (item) => {
		// Call API to reload data with new type
		this.props.getData({
			page: 1,
			topic: item.link,
			postId: this.props.activePostId,
			numberOfPosts: 5,
		});
		this.setState({ topicCategory: item.link, page: 1 });
	};

	jumpPage(value) {
		this.props.getData({
			page: this.state.page + value,
			topic: this.state.topicCategory,
			postId: this.props.activePostId,
			numberOfPosts: 5,
		});
		this.setState({ page: this.state.page + value });
	}

	convertDateTime(dt) {
		return (
			new Date(dt).getDate().toString().padStart(2, "0") +
			"/" +
			(new Date(dt).getMonth() + 1).toString().padStart(2, "0") +
			"/" +
			new Date(dt).getFullYear()
		);
	}

	render() {
		let listUsefulInfo;
		// let srcBgrImg = "/images/slide3.jpg"
		let listType;
		let totalPage;
		let name,
			time,
			author,
			views = 0;
		if (this.props.totalPage) {
			totalPage = this.props.totalPage;
		}
		if (this.props.dataUsefulInfo) {
			listUsefulInfo = this.props.dataUsefulInfo.description;
			// const firstSrcImg = listUsefulInfo.match(/src="[^"]+/)
			name = this.props.dataUsefulInfo.title;
			time = this.convertDateTime(this.props.dataUsefulInfo.last_update_time);
			author = this.props.dataUsefulInfo.author;
			views = this.props.dataUsefulInfo.views;
			// if (firstSrcImg)
			// srcBgrImg = firstSrcImg[0].toString().substring(5)
		}

		if (this.state.postLinks) {
			listType = this.state.postLinks.map((post, idx) => {
				let smallDesc = "";
				const pArr = new DOMParser()
					.parseFromString(post.description, "text/html")
					.getElementsByTagName("p");
				for (let i = 0; i < pArr.length; i++)
					smallDesc += pArr[i].textContent ? pArr[i].textContent + " " : "";
				// Cannot use positive look behind on safari => Remove lookbehind
				let srcRegex = post.description.match(/(?:src=")[^"]+/);
				let firstImgDesc = "";
				if (srcRegex) {
					firstImgDesc = srcRegex.toString().substring(5); // Remove src="
				}
				return (
					<a
						// onClick={() => this.changePost(post.id)}
						href={
							"/beauty/" +
							convertVietnameseToEnglishString(post.title) +
							"-" +
							post._id
						}
						key={idx}
						className="list-event-new"
					>
						<div className="row row-list-reference">
							<div className="col-md-12 col-lg-4 no-padding img-reference">
								<img
									className="img-post-link"
									src={firstImgDesc}
									alt={idx}
									// style={{ objectFit: "cover", height: 100 }}
								/>
							</div>
							<div className="col-md-12 col-lg-8 content-reference">
								<div className="title-link-usa-full-info">
									{post.title.toUpperCase()}
								</div>
								{smallDesc && <div className="desc-link">{smallDesc}</div>}
							</div>
						</div>
					</a>
				);
			});
		} else {
			listType = <div></div>;
		}
		console.log(this.state.topic);
		return (
			<div className="wrap-event-info use-full-link-info">
				<div className="wrap-event-name">
					<h3 className="bolder-title main-type-color">VẺ ĐẸP MỸ</h3>
				</div>
				<div className="container-travel">
					<div className="row" style={{ marginBottom: "calc(100vw*30/1434)" }}>
						<div className="col-md-8">
							<div>
								<div>
									<h3 className="bolder-title">{name}</h3>
									<h6 className="post-infor-row">
										{/* <i className="post-infor-header">Đăng ngày: </i> */}
										<i>{time}</i>
									</h6>
									<h6 className="post-infor-row">
										{/* <i className="post-infor-header">Tác giả: </i> */}
										<i>{author}</i>
									</h6>
									<h6 className="post-infor-row">
										{/* <i className="post-infor-header">Lượt xem: </i> */}
										<i>{views + " lượt xem"}</i>
									</h6>
								</div>
								<PostContent content={listUsefulInfo} />
								<div style={{ margin: "10px 0" }}>
									<PostSocialBar />
								</div>
							</div>

							{/* <div className="row row-share">
                                <img className="icon-schedule" src={'/images/print.png'} />
                                <img className="icon-schedule" src={'/images/like.png'} />
                                <img className="icon-schedule" src={'/images/facebook.jpg'} />
                                </div> */}
						</div>

						{/* <div className="col-md-1"></div> */}
						<div className="col-md-4 ">
							<div className="form-sticky moreInfoEvent">
								<div className="wrap-usas">
									<Dropdown
										title="CHỦ ĐỀ"
										list={this.state.topic}
										onChange={this.onTopicChange}
										styles={{
											checkIcon: { display: "none" },
										}}
									/>
								</div>
								<div
									className="wrap-new-event-info"
									// style={{ marginTop: "calc(100vw*20/1434)" }}
								>
									{listType}
									{totalPage !== 0 && (
										<div className="col-12 text-center row--center">
											{this.state.page > 1 && (
												<button
													className="button--transparent page__icon-btn"
													onClick={() => this.jumpPage(-1)}
												>
													<img
														src="/images/left-arrow.svg"
														width="30"
														alt="Mũi tên page nuocmy.net"
													/>
												</button>
											)}
											<div className="page__text mg--16">
												{this.state.page} of {totalPage}
											</div>
											{this.state.page !== totalPage && (
												<button
													className="button--transparent page__icon-btn"
													onClick={() => this.jumpPage(1)}
												>
													<img
														src="/images/right-arrow.svg"
														width="30"
														alt="Mũi tên page nuocmy.net"
													/>
												</button>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default USBeauty;
