import React from 'react'
import "../styles/faq.css"
import FAQContainer from '../containers/FAQPage/FAQContainer'
class FAQPage extends React.Component {
  render() {
    return (
      <div className="FAQ">
        <FAQContainer />
      </div>
    );
  }
}

export default FAQPage;