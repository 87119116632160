import React, { Component } from 'react';
import Contact from '../../components/contactPage/Contact'
import { AddContact, resetAction } from "../../actions/ContactAction"
import { connect } from "react-redux"
class ContactContainer extends Component {
    render() {
        return (
            <Contact {...this.props} />
        );
    }
}
const mapStateToProps = (store) => {
    return {
        isAdded: store.AddContact.isAdded,
        isSending: store.AddContact.isSending,
        texts: store.texts.listText,
    }
}
const mapDispacthToProps = (dispatch) => {
    return {
        sendContact: (payload) => {
            dispatch(AddContact(payload))

        },
        resetAction: () => {
            dispatch(resetAction())

        }
    }
}
export default connect(mapStateToProps, mapDispacthToProps)(ContactContainer)
