import React, { Component } from 'react';
import Header from '../../components/common/Header'
import { connect } from 'react-redux';
import * as actions from '../../actions/HeaderActions';
import * as act from '../../actions/HomePage';
import { 
    CALL_GOOGLE_SEARCH_REQUEST,
    TURN_OFF_GOOGLE_MODAL,
} from '../../constant'

class HeaderContainer extends Component {
    componentDidMount() {
        this.props.initLoad()
    }

    render() {
        return (
            <Header {...this.props} />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        newEvents: state.headerReducer.events,
        postTopics: state.usaCategory.listCategoryUsa,
        tourTypes: state.cat.listCategoryTour,
        texts: state.texts.listText,
        googleObj: state.headerReducer.googleObj,
        isOpenResultModal: state.headerReducer.openGoogleResultModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        initLoad: () => {
            dispatch(actions.getTourTypes())
            dispatch(actions.getNewEvents())
            dispatch(actions.getUSAPostTopics())
            dispatch(act.getTextNew())
        },
        callGoogleCustomSearch: keyword => {   
            dispatch({ type: CALL_GOOGLE_SEARCH_REQUEST, payload: keyword })
        },
        closeResultModal: () => {
            dispatch({ type: TURN_OFF_GOOGLE_MODAL })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);