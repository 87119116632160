import React, { Component } from 'react';
class AboutUs extends Component {
    render() {
        let list
        let introHead
        let introBot
        if (this.props.listData) {
            list = this.props.listData.map((item, key) => {
                // let nodeP = item.description.split("\n").map((item, idx) => {
                //     return (
                //         <p key={idx}>{item}</p>
                //     );
                // });
                return (
                    <div className="row" key={key}>
                        <div className="col-md-4 text-center">
                            <img className="img-key-mem" src={item.avatar.src} alt={item.avatar.alt} />
                        </div>
                        <div className="col-md-8">
                            <h4 className="name-key-mem" style={{ color: "#636363" }}>{item.name}</h4>
                            <div className="desc-key-mem" style={{ color: "#777777" }}>
                                {/* {nodeP} */}
                                <p className="desc-us-p">{item.description}</p>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        if (this.props.listIntro) {
            // let nodeP = this.props.listIntro[0].text.split("\n").map((item, idx) => {
            //     return (
            //         <p key={idx}>{item}</p>
            //     );
            // });
            introHead = (
                <div className="desc-us-top" style={{ color: "#777777" }}>
                    {/* {nodeP} */}
                    <p className="desc-us-p">{this.props.listIntro[0].text}</p>
                </div>
            )
        }
        if (this.props.listIntro) {
            // let nodeP = this.props.listIntro[1].text.split("\n").map((item, idx) => {
            //     return (
            //         <p key={idx}>{item}</p>
            //     );
            // });
            introBot = (
                <div className="desc-us-bot" style={{ color: "#777777" }}>
                    {/* {nodeP} */}
                    <p className="desc-us-p">{this.props.listIntro[1].text}</p>
                </div>
            )
        }
        return (
            <div className="wrap-about-us">
                <div className="image-head-about-us" style={{
                    // backgroundImage: "url(/images/image_about_us.jpg)"
                }}>
                    <div className="container-travel wrap-text-head-aboutus wrap-page-intro-block">
                        <h1>VỀ CHÚNG TÔI</h1>
                    </div>
                </div>
                <div className="container-travel container-aboutus">
                    {/* <br /> */}
                    {introHead}
                    {list && list.length
                        ?
                        <div>
                            <h2 className="title-center title-key-member">ĐỘI NGŨ</h2>
                            <div className="list-key-member">
                                {list}
                            </div>
                        </div>
                        : <div></div>}

                    {introBot}
                </div>
            </div >
        );
    }
}
export default AboutUs;
