import React, { Component } from 'react';

class Header extends Component {
    render() {
        let listTourInfo
        if (this.props.dataTourInfo) {
            listTourInfo = (
                <div className="tourInfo-title">
                    <div className="back-head-tour-info" alt={this.props.dataTourInfo.cover_img.alt} style={{
                        backgroundImage: 'url(' +  this.props.dataTourInfo.cover_img.src + ')'
                    }}>
                        <div className="duration-tour text-up-shadow">{this.props.dataTourInfo.duration + " ngày"}</div>
                        <h1 className="h1-name-tour text-up-shadow">{this.props.dataTourInfo.name}</h1>
                        <div className="tour-code text-up-shadow">tour code : ({this.props.dataTourInfo.code}) </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {listTourInfo}
            </div>
        );
    }
}
export default Header;
