import React from 'react'
import "../styles/not-found.css"

export default class NotFoundPage extends React.Component {
    render () {
        return (
            <div className="wrap-not-found-page">
                <div className="error-status-code">
                    404
                </div>
                <div className="error-title">
                    Không tìm thấy !!!
                </div>
                <div className="error-description">
                    Đường dẫn bạn yêu cầu không tồn tại hoặc đã bị xóa.
                    Vui lòng kiểm tra lại đường dẫn.
                </div>
            </div>
        )
    }
}