import { put, takeEvery } from 'redux-saga/effects';
import getImageTour from '../../fetchAPI/HomePage/ImageTourAPI';
import * as types from '../../constant';
function* getImage() {
  try {
    const res = yield getImageTour();
    yield put({
      type: types.GET_IMAGE_TOUR_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_IMAGE_TOUR_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const ImageTourSaga = [
  takeEvery(types.GET_IMAGE_TOUR_REQUEST, getImage)
];
