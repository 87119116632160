import React from 'react';
import "../styles/tour.css"
import ListTourContainer from '../containers/TourPage/ListTourContainer';
const TourPage = ({routerObj}) => {
    return (
      <div className="TourPage">
        <ListTourContainer routerObj={routerObj}/>
      </div>
    );
}

export default TourPage;