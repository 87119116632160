import { put, takeEvery } from 'redux-saga/effects';
import getList from '../fetchAPI/getListEvent'
import * as types from '../constant';

function*getListEvent(action) {
  try {
    //page:activePage, cat=cat
    const respone = yield getList(action.payload.page);
    
    yield put({
      type: types.GET_EVENTLIST_SUCCESS,
      payload: respone
     
    } );
  } catch (error) {
    
    yield put({
      type: types.GET_EVENTLIST_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const ListEventSaga = [
  takeEvery(types.GET_EVENTLIST_REQUEST, getListEvent)
];