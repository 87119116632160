import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "reactjs-dropdown-component";
import { convertVietnameseToEnglishString } from "../../utils/common";
import history from "../../history";

library.add(faChevronLeft, faChevronRight);
//var backMore = '/images/slide3.jpg';
class ListTour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			catName: " ",
			tab: " ",
			page: 1,
			topic: [],
			postLinks: "",
			typeObjs: "",
		};
	}

	componentDidMount() {
		// Change type tab depending on the param that is got from urlx
		const pathName = this.props.routerObj.location.pathname;
		const type = pathName.split("/").pop().split("?").shift();

		if (type !== "tours") {
			// Create a callback function to change exactly type when receive response
			// const setExactTypeToStateCallBack
			// = (exactType) => this.setState({ catName: exactType, tab: exactType })
			this.props.getList({ page: 1, cat: type });

			// Set state so that we can render tab active style
			this.setState({ tab: type });
		} else {
			this.props.getList({ page: 1, cat: " " });
			this.setState({ tab: " " });
		}
	}

	componentWillReceiveProps(nextProps) {
		let topic = nextProps.dataCategory.map((item, key) => ({
			id: key + 1,
			item,
			selected: false,
			key: "topic",
			title: item.type,
			label: item.type,
			value: item.type_unicode,
		}));
		this.setState({
			topic: [
				{
					id: 0,
					title: "Tất cả",
					// item: { type: "Tất cả", type_unicode: " " },
					selected: false,
					key: "topic",
					label: "Tất cả",
					value: " ",
				},
				...topic,
			],
		});
	}

	onTopicChange = (item) => {
		// Call API to reload data with new type
		this.props.getList({
			page: 1,
			cat: item.value,
		});
		this.setState({ tab: item.value, page: 1 });
		// Rename the tab category
		let type = item.label === "Tất cả" ? "" : "/" + item.value;
		// Then change url
		history.push("/tours" + type);
	};

	changeTab(tabCat) {
		if (tabCat !== this.state.tab) {
			this.props.getList({ page: 1, cat: tabCat });
			this.setState({ page: 1, tab: tabCat, catName: tabCat });
			// Rename the tab category
			let type = tabCat === " " ? "" : "/" + tabCat;
			// Then change url
			history.push("/tours" + type);
		}
	}

	addView() {
		this.props.getList({
			page: this.state.page + 1,
			cat: !this.state.tab ? " " : this.state.tab,
		});
		this.setState({ page: this.state.page + 1 });
	}

	render() {
		const url = this.props.routerObj.history.createHref({
			pathname: "/tour-info/",
		});
		let category = this.props.dataCategory;
		let tour = this.props.dataListTour;
		let listTour;
		let listCategory;
		let totalPage;
		if (this.props) {
			totalPage = this.props.totalPage;
		}
		if (category) {
			listCategory = category.map((cat, key) => {
				return (
					<div
						key={key}
						className={
							this.state.tab === cat.type_unicode
								? "tab-tour-active"
								: "tab-tour"
						}
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
						onClick={() => this.changeTab(cat.type_unicode)}
					>
						{cat.type}
						{this.state.tab === cat.type_unicode && (
							<div className="triangle"></div>
						)}
					</div>
				);
			});
		}
		if (tour) {
			listTour = tour.map((item, key) => {
				return (
					<div key={key} className="col-lg-3 col-md-6 item-child-col">
						<div className="wrap-tour">
							<a
								className="reset-a-parent"
								href={
									url +
									convertVietnameseToEnglishString(item.name) +
									"-" +
									item._id
								}
							>
								<img
									className="img-tour"
									alt={item.cover_img.alt}
									src={item.cover_img.src}
								/>
								<div className="tour-item-bg"></div>
								<div className="wrap-info-tour">
									<div className="row">
										<div className="col-9 text-tour">
											<div className="number-day">
												{item.duration + " ngày"}
											</div>
											<div className="name-tour">{item.name}</div>
										</div>
										<div className="col-3">
											<i className="detail-tour">→</i>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				);
			});
		}
		let title = "",
			content = "";
		if (this.props.texts.bodyText) {
			title = this.props.texts.bodyText.tours.text.title;
			content = this.props.texts.bodyText.tours.text.content;
		}
		return (
			<div className="">
				<div className="image-head-tour">
					<div className="container-travel wrap-text-head tour-header">
						<div className="container-travel wrap-page-intro-block">
							<h1>{title}</h1>
							<p className="auto-next-line-p">{content}</p>
						</div>
						<div className="wrap-tab-tour">
							<div className=" row-tab-tour">
								{/* <img className="img-arrow-tour-left" src={'/images/arrow-left.png'} alt="" /> */}
								<div
									className={
										this.state.tab === " " ? "tab-tour-active" : "tab-tour"
									}
									onClick={() => {
										this.changeTab(" ");
									}}
								>
									Tất cả
									{this.state.tab === " " && <div className="triangle"></div>}
								</div>
								{listCategory}
								{/* <img className="img-arrow-tour-right" src={'/images/arrow-right.png'} alt="" /> */}
							</div>
						</div>
					</div>
				</div>
				<div className="wrap-list-tour">
					<div className="container-travel">
						<div className="row row-listTour">
							<Dropdown
								title="CHỦ ĐỀ"
								list={this.state.topic}
								onChange={this.onTopicChange}
								styles={{
									checkIcon: { display: "none" },
								}}
							/>
							{listTour}
						</div>

						{totalPage !== this.state.page && totalPage !== 0 && (
							<div className="col-12 text-center wrap-btn-support wrap-more-list-tour">
								<button
									className="btn-contact btn btn-danger btn-more-home btn-see-more-tour"
									style={{ color: "white" }}
									onClick={() => {
										this.addView();
										// TODO: Call get all tour API
									}}
								>
									<span className="span-more-list-tour">Xem thêm</span>
									<i className="icon-arrow-btn">→</i>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default ListTour;
