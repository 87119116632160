import React, { Component } from 'react';
import { connect } from 'react-redux'
// import * as actions from '../../actions/ListTourInfoActions'
import Schedule from '../../components/tourInfoPage/Schedule'

class ScheduleContainer extends Component {
    
    render() {
        return (
            <Schedule {...this.props}/>
        );
    }

}
const mapStateToProps = (state) => {
    return {
      dataTourInfo:state.tourInfo.tourInfo
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
   
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(ScheduleContainer);

// export default ScheduleContainer;
