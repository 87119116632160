import { put, takeEvery } from 'redux-saga/effects';
import getCategoryUSA from '../../fetchAPI/UsaApi/getCategoryUSA';
import * as types from '../../constant';
function* getListCategory() {
  try {
    const res = yield getCategoryUSA();
    yield put({
      type: types.GET_CATEGORY_USA_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_CATEGORY_USA_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const getCategoryUsa = [
  takeEvery(types.GET_CATEGORY_USA_REQUEST, getListCategory)
];
