import React, { Component } from 'react'
// import "react-responsive-carousel/lib/styles/carousel.min.css"
// import { Carousel } from 'react-responsive-carousel'

class Highlight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedImgIdx: 0,
            // Turn on automatic change slide mode when
            // user view in mobile screen
            auto: window.matchMedia("(max-width: 600px)").matches, 
        };
    }

    componentDidMount = () => {
        // Trigger auto slide mode
        if(this.state.auto){
            // Change slide every 2.5 seconds
            const autoSlideInterval = setInterval(this.nextSlide, 2500)
            this.setState({ autoSlideInterval })
        }
    }

    componentWillUnmount = () => {
        // Clear auto slide loop
        const { autoSlideInterval } = this.state
        if(autoSlideInterval) {
            clearInterval(autoSlideInterval)
        }
    }

    // handleSelect(selectedIdx) {
    //     this.setState({
    //         selectedImgIdx: selectedIdx
    //     });
    // }

    prevSlide = () => {
        const { data } = this.props
        if(data && data.length) {
            this.setState(prevState => ({
                selectedImgIdx: prevState.selectedImgIdx - 1 < 0
                    ? data.length - 1
                    : prevState.selectedImgIdx - 1
            }))
        }
    }

    nextSlide = () => {
        const { data } = this.props
        if(data && data.length) {
            this.setState(prevState => ({
                selectedImgIdx: prevState.selectedImgIdx + 1 === data.length
                    ? 0
                    : prevState.selectedImgIdx + 1
            }))
        }   
    }

    fixImageTextWidth = () => {
        // Find dom elements
        let imgDOM = document.getElementsByClassName("safe-image")
        let txtDOM = document.getElementsByClassName("desc-highligth")
        // Found some valid element
        if(imgDOM.length && txtDOM.length) {
            
            const maxTxtWidth = imgDOM[0].width
            txtDOM[0].style.maxWidth = maxTxtWidth.toString() + "px"
        }
    }

    render() {
        // let listData;
        // if (this.props.data) {
        //     listData = this.props.data.map((item, key) => {
        //         return (
        //             <div key={key} style={{ background: "transparent" }}>
        //                 <img className="slide-img" src={item.name} alt={item.alt} />
        //                 {/* <div className="desc-highligth">
        //                     {item.description}
        //                 </div> */}
        //             </div>
        //         )
        //     })
        // }
        const data = this.props.data, 
            selectedIdx = this.state.selectedImgIdx
        let title = "", content = ""
        if (this.props.texts.bodyText) {
            title = this.props.texts.bodyText.highlight_tours.text.title
            content = this.props.texts.bodyText.highlight_tours.text.content
        }

        return (
            <div className="wrap-highlight">
                <div
                // className="image-head-highlight" 
                // style={{ backgroundImage: "url(/images/slide3.jpg)"}}
                >
                    <div className="container-travel wrap-text-head wrap-page-intro-block">
                        <h1>{title}</h1>
                        <div className="row" style={{ marginBottom: 12 }}>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                <p className="auto-next-line-p">{content}</p>
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                    </div>
                </div>
                <div className="container-travel">
                    <div className="wrap-list-highligth">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                <div className="row wrap-highlight-tour-slide">
                                    <div className="col-sm-1 wrap-button-slide">
                                        <button 
                                            className="arrow-button-slide"
                                            onClick={this.prevSlide}>
                                            <img 
                                                src="/images/arrow-left.png" 
                                                alt="Mũi tên slide nuocmy.net"/>
                                        </button>
                                    </div>
                                    <div className="col-sm-10 slide-content">
                                        {
                                            data
                                            && data.length
                                            &&
                                            <img
                                                className="safe-image"
                                                onLoad={this.fixImageTextWidth}
                                                src={data[selectedIdx].name}
                                                alt={data[selectedIdx].alt}
                                            />
                                        }
                                        <div className="desc-highligth">
                                            {
                                                data 
                                                && data.length
                                                && data[selectedIdx].description
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-1 wrap-button-slide">
                                        <button 
                                            className="arrow-button-slide"
                                            onClick={this.nextSlide}>
                                            <img 
                                                src="/images/arrow-right.png"
                                                alt="Mũi tên slide nuocmy.net"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Highlight;
