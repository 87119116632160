import React, { Component } from 'react'
import Routes from './routes'
// import HeaderContainer from './containers/common/HeaderContainer'
import FooterContainer from './containers/common/FooterContainer'
import LoadingContainer from '../src/containers/common/LoadingContainer'

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <HeaderContainer /> */}
        <Routes />
        <div className="backToTop">
          <a href="#root">
            <img src={'/images/arrow-up.png'} alt=""></img>
          </a>
        </div>
        <div style={{
          position: "relative",
          zIndex: 1000
        }}>
          <LoadingContainer />
        </div>
        <FooterContainer />
        
      </div>
    );
  }
}

export default App;
