import React from 'react';
import "../styles/usefullinfo.css"
import UsefulContainer from '../containers/UsefulInfoPage/UsefulInfoContainer';
class UsefulInfoPage extends React.Component {
  render() {
    return (
      <div className="useful">
        <UsefulContainer />
      </div>
    );
  }
}

export default UsefulInfoPage;