import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as page from './pages';
import HeaderContainer from './containers/common/HeaderContainer';

const TransparentHeader = ({ children }) => (
  <>
    <HeaderContainer isTransparent/>
    {children}
  </>
)

const DefaultHeader = ({ children }) => (
  <>
    <HeaderContainer/>
    {children}
  </>
)

const Routes = () => (
  <BrowserRouter >
    <Switch>
      <Route exact path="/" 
        component={() => (<TransparentHeader><page.HomePage/></TransparentHeader>)} 
      />
      <Route path="/events"
        component={() => (<DefaultHeader><page.EventPage/></DefaultHeader>)}
      />
      <Route path="/tours"
        component={routerObj => (<DefaultHeader><page.TourPage routerObj={routerObj}/></DefaultHeader>)}
      />
      <Route path="/tour-info"
        component={() => (<DefaultHeader><page.TourInfoPage/></DefaultHeader>)}
      />
       <Route path="/faq"
        component={() => (<DefaultHeader><page.FAQPage/></DefaultHeader>)}
      />
      <Route path="/contact"
        component={() => (<DefaultHeader><page.ContactPage/></DefaultHeader>)}
      />
      <Route path="/highlight-tours"
        component={() => (<DefaultHeader><page.HighlightTourPage/></DefaultHeader>)}
      />
      <Route path="/about-us"
        component={() => (<DefaultHeader><page.AboutUsPage/></DefaultHeader>)}
      />
      <Route path="/event-info"
        component={() => (<DefaultHeader><page.EventInfoPage/></DefaultHeader>)}
      />
      <Route path="/usas"
        component={() => (<DefaultHeader><page.USAsPage/></DefaultHeader>)}
      />
      <Route path="/price-request"
        component={() => (<DefaultHeader><page.PriceRequestPage/></DefaultHeader>)}
      />
      <Route path="/post"
        component={() => (<DefaultHeader><page.UsefulInfoPage/></DefaultHeader>)}
      />
      <Route path="/beauty"
        component={() => (<DefaultHeader><page.USBeautyPage/></DefaultHeader>)}
      />
      <Route path="/terms" 
        component={() => (<DefaultHeader><page.TermPage/></DefaultHeader>)}
      />
      <Route path="/search"
        component={() => (<DefaultHeader><page.GoogleSearchPage/></DefaultHeader>)}
      />
      <Route path="*" component={() => (<DefaultHeader><page.NotFoundPage /></DefaultHeader>)}/>
        
    </Switch>
  </BrowserRouter>
);

export default Routes;