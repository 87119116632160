import { WEB_NAME } from "../constant"

export const sharingFacebook = () => {
    // Trigger sharing function of facebook using current url
    window.FB.ui({
        method: 'share',
        href: window.location.href,
    })
}

// export const sharingToFBWithContent = ({title, description, imgUrl}) => {
//     window.FB.ui({
//         method: 'share_open_graph',
//         action_type: 'og.shares',
//         action_properties: JSON.stringify({
// 			object: {
// 				'og:url': window.location.href,
// 				'og:title': title,
// 				'og:description': description,
// 				'og:image': imgUrl
// 			}
// 		}),
//     });
// }

export const convertStringToUrl = (str) => {
    return str
        .trim()
        .replace(/-/g, "")      /* Negate '-' char */
        .replace(/\s/g, "-")    /* Replace white space to '-' */
        .replace(/-+/g, "-")    /* Convert multi '-' char to 1 '-' */
        .toLowerCase()          /* Convert to lower case */
}

export const getParamFromUrl = (routerObj) => {
    const { location, match } = routerObj
    const regex = new RegExp(`${match.path}/?`)
    return location.pathname
        .replace(regex, '')     /* Remove matches url */
        .replace(/-/g, " ")     /* Decode to get all whitespace */
        .replace(/_/g, "-")     /* Decode to get escape char */
}

export const getIdFromUrl = () => {
    // Get last string separated by '-' char, remove any query param (if sharing on social)
    return window.location.href.split("?").shift().split("/").pop().split("-").pop()
}

export const getLastRouteFromUrl = () => {
    // Get last route separated by '/' char, remove any query param (if sharing on social)
    return window.location.href.split("?").shift().split("/").pop()
}

export const convertVietnameseToEnglishString = (str) => {
    // Initialize result string
    let result = ''
    // Loop each character
    convertStringToUrl(str).split('').forEach(char => {
        // Convert vietnamese char to english char
        switch(char) {
            case "á":
            case "à":
            case "ả":
            case "ạ":
            case "ã":
            case "ă":
            case "ằ":
            case "ắ":
            case "ẳ":
            case "ẵ":
            case "ặ":
            case "â":
            case "ầ":
            case "ấ":
            case "ẩ":
            case "ẫ":
            case "ậ":
                result += "a"
                break
            case "đ":
                result += "d"
                break
            case "ò":
            case "ó":
            case "ỏ":
            case "õ":
            case "ọ":
            case "ô":
            case "ố":
            case "ồ":
            case "ổ":
            case "ỗ":
            case "ộ":
            case "ơ":
            case "ờ":
            case "ớ":
            case "ở":
            case "ỡ":
            case "ợ":
                result += "o"
                break
            case "è":
            case "é":
            case "ẻ":
            case "ẹ":
            case "ẽ":
            case "ê":
            case "ề":
            case "ế":
            case "ể":
            case "ễ":
            case "ệ":
                result += "e"
                break
            case "í":
            case "ỉ":
            case "ị":
            case "ì":
            case "ĩ":
                result += "i"
                break
            case "ú":
            case "ù":
            case "ủ":
            case "ụ":
            case "ũ":
            case "ư":
            case "ừ":
            case "ứ":
            case "ử":
            case "ữ":
            case "ự":
                result += "u"
                break
            case "ý":
            case "ỳ":
            case "ỷ":
            case "ỵ":
            case "ỹ":
                result += "y"
                break
            default:
                result += char
                break
        }
    })
    // Remove invalid char
    return result
        .replace(/[^A-Za-z0-9-]/g, "")
        .replace(/-_?-/g, "-")
        .replace(/-+$/g, "")            /* Remove last '-' chars*/
}

export const changeTabTitle = (mainTitle) => {
    // Change tab title follow the formula: [main title] - [web name]
    // Ex: My beautiful country - GotUSA
    document.title =  `${mainTitle || ""} - ${WEB_NAME}`
}
// eslint-disable-next-line
String.prototype.toFormatPrice = function() {
    let validPrice = ""
    for (let i = 0; i < this.length; i++) {
        const char = this.charAt(i)
        if (char.match(/[0-9]/))
            validPrice += char
        if (i !== this.length - 1 && (this.length - i - 1) % 3 === 0)
            validPrice += ","
    }
    return validPrice
}

export const filterTourByType = (tourTypes, selectedTourType) => {
    try {
        return tourTypes.find(item => item.tour_type === selectedTourType).tours || []
    } catch {
        return []
    }
}

export const getFormatedInputDate = (date) => {
    if(!date)
        return `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`
    try {
        let dateObj = new Date(date)
        return `${dateObj.getFullYear()}-${(dateObj.getMonth()+1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`
    } catch {
        let dateObj = new Date()
        return `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`
    }
}

export const getTomorrowFormatedDate = () => {
    let tommorow = new Date()
    tommorow.setDate(tommorow.getDate() + 1)
    return tommorow.getFullYear()
        + "-" + (tommorow.getMonth() + 1).toString().padStart(2, "0")
        + "-" + tommorow.getDate().toString().padStart(2, "0")
}