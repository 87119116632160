import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import * as EmailValidator from 'email-validator'
// import * as PhoneValidator from 'vn-phone-validator';
import NotificationModal from '../common/NotificationModal'
import ErrorModal from '../common/ErrorModal'

class Contact extends Component {

    constructor(props) {
        super(props)
        this.state = {
            enableBtn: false,
            ...this.getDefaultState()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAdded) {
            this.modal.open()
            this.setState(this.getDefaultState(), this.props.resetAction());
        }
    }

    getDefaultState() {
        return {
            fullName: "",
            email: "",
            phoneNum: "",
            otherContact: "",
            note: "",
            placeholder: "",
            nameIsValid: false,
            emailIsValid: false,
            phoneIsValid: false,
            noteIsValid: false,
            firstTime: true,
        }
    }

    onChange = (value) => {
        this.setState({ enableBtn: !!value })
    }

    checkEmailAndPhone() {
        let validEmail = EmailValidator.validate(this.state.email)
        // let validPhone = PhoneValidator.validate(this.state.phoneNum).isValidate
        let validPhone = this.state.phoneNum.match(/^\+?[0-9 ]{10,}$/)
        this.setState({ emailIsValid: validEmail, phoneIsValid: validPhone })
        return validEmail && validPhone
    }

    showErrorModal = () => {
        const validEmail = EmailValidator.validate(this.state.email)
        const validPhone = this.state.phoneNum.match(/^\+?[0-9 ]{10,}$/)
        const validName = !!this.state.fullName.trim()
        const validNote = !!this.state.note.trim()

        let err = "Bạn cần nhập "
        if(!validEmail)
            err += "email"
        if(!validPhone) {
            if (!validEmail) err += ", "
            err += "số điện thoại"
        }
        if(!validEmail || !validPhone)
            err += " hợp lệ"
        if(!validName || !validNote) {
            if(!validEmail || !validPhone) err += ", "
            err += "đầy đủ "
        }
        if(!validName) {
            err += "họ tên"
        }
        if(!validNote){
            if(!validName) err += ", "
            err += "yêu cầu"
        }
        err += "."

        this.errorModal.open(err)
    }

    render() {
        let title = "", content = ""
        if(this.props.texts.bodyText) {
            title = this.props.texts.bodyText.contact.text.title
            content = this.props.texts.bodyText.contact.text.content
        }
        return (
            <div className="wrap-contact">
                <div className="container-travel container-contact">
                    <h1 className="title-center title-top-contact wrap-page-intro-block">{title}</h1>
                    <p className="desc-contact desc-contact-text auto-next-line-p">
                        {content}
                    </p>
                    <div className="form-contact contact-form">
                        <div className="row contact-row">
                            <div className="col-md-6 contact-col">
                                <div className="title-form title-input-contact">Họ tên của bạn (*)</div>
                                <input className={"form-control form-input-contact " + ((this.state.firstTime || this.state.nameIsValid) ? "" : "classValidate")}
                                    type="text"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            fullName: e.target.value,
                                            nameIsValid: !!e.target.value.trim()
                                        })
                                    }}
                                    value={this.state.fullName}
                                />
                                
                                <div className="title-form title-input-contact">Số điện thoại (*)</div>
                                <input className={"form-control form-input-contact " + ((this.state.firstTime || this.state.phoneIsValid) ? "" : "classValidate")}
                                    type="text"
                                    placeholder={this.state.placeholder}

                                    onChange={(e) => {
                                        this.setState({
                                            phoneNum: e.target.value,
                                            phoneIsValid: !!e.target.value.trim() || !!this.state.email.trim()
                                        })
                                    }}
                                    value={this.state.phoneNum}
                                />
                               
                                
                            </div>
                            <div className="col-md-6">
                                {/* <img
                                    className="body-img image-contact"
                                    src="/images/contact-body-image.jpg"
                                    alt="anh"
                                /> */}
                                <div className="title-form title-input-contact">Địa chỉ email (*)</div>
                                <input className={"form-control form-input-contact " + ((this.state.firstTime || this.state.emailIsValid) ? "" : "classValidate")}
                                    type="email"
                                    placeholder={this.state.placeholder}
                                    onChange={(e) => {
                                        this.setState({
                                            email: e.target.value,
                                            emailIsValid: !!e.target.value.trim() || !!this.state.phoneNum.trim()
                                        })
                                    }}
                                    value={this.state.email}
                                />

                                <div className="title-form title-input-contact">Phương thức liên lạc khác</div>
                                <input className="form-control form-input-contact" placeholder="Facebook, Viber, Zalo ..." type="text"
                                    onChange={(e) => {
                                        this.setState({
                                            otherContact: e.target.value
                                        })
                                    }}
                                    value={this.state.otherContact}

                                />

                            </div>

                            <div className="row col-md-12" >
                                <div className="title-form title-input-contact">Chúng tôi có thể giúp gì cho bạn (*)</div>
                                    <textarea
                                        className={
                                            "form-control textarea-input-contact form-input-contact " + (
                                                (this.state.firstTime || this.state.noteIsValid)
                                                    ? "" : "classValidate"
                                            )}
                                        onChange={(e) => {
                                            this.setState({
                                                note: e.target.value,
                                                noteIsValid: !!e.target.value.trim()
                                            })
                                        }}
                                        style={{ resize: "none" }}
                                        value={this.state.note}
                                        placeholder={this.state.placeholder}
                                    ></textarea>
                                    <div style={{width: 'inherit'}} className="title-form text-center title-input-contact">Xác nhận</div>
                                    <div style={{width: 'inherit'}} className="text-center">
                                        <ReCAPTCHA
                                            className="captcha"
                                            hl="vi"
                                            sitekey="6LdL57QUAAAAAPOEyIFrjGYeChKMa7bV8M-lzqsM"
                                            onChange={this.onChange}
                                        />

                                        {
                                            this.props.isSending
                                            ? <div>Đang xử lý yêu cầu! Vui lòng chờ giây lát ...</div>
                                            : <button style={{ color: "white", fontWeight: "bold" }}
                                                disabled={!this.state.enableBtn}
                                                className={
                                                    this.state.enableBtn
                                                    ? "btn-contact btn btn-danger btn-more-home btn-contact-request btn-send"
                                                    : "btn-contact btn btn-danger btn-send"
                                                }
                                                onClick={() => {
                                                    if (this.checkEmailAndPhone() && this.state.fullName.trim() && this.state.note.trim()) {
                                                        this.props.sendContact(this.state)
                                                    } else {
                                                        this.showErrorModal()
                                                        this.setState({
                                                            firstTime: false,
                                                            placeholder: "Mời điền thông tin vào đây",
                                                            nameIsValid: !!this.state.fullName.trim(),
                                                            noteIsValid: !!this.state.note.trim()
                                                        })
                                                    }
                                                }}>
                                                <span>GỬI YÊU CẦU</span>
                                                <i className="icon-arrow-btn">→</i>
                                            </button>
                                        }
                                        <NotificationModal ref={ref => this.modal = ref}/>
                                        <ErrorModal ref={ref => this.errorModal = ref}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact