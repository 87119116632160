import { put, takeEvery } from 'redux-saga/effects';
import getImage from '../../fetchAPI/HomePage/ImageDefault';
import * as types from '../../constant';
function* getImageDefault() {
  try {
    const res = yield getImage();
    yield put({
      type: types.GET_IMAGE_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_IMAGE_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const ImageDefaultSaga = [
  takeEvery(types.GET_IMAGE_REQUEST, getImageDefault)
];
