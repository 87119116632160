import React, { Component } from 'react';
import { connect } from 'react-redux'
import Map from '../../components/tourInfoPage/Map'
// import * as actions from '../../actions/ListTourInfoActions'
class MapContainer extends Component {
    render() {
        return (
            <Map {...this.props} />
        );
    }

}
const mapStateToProps = (state) => {
    return {
      dataTourInfo:state.tourInfo.tourInfo
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(MapContainer);
// export default MapContainer;
