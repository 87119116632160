import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { TEXT_SEARCH_STORAGE_KEY } from "../../constant";

/**
 * <script async src="https://cse.google.com/cse.js?cx=016941719398994561432:fny96mzmha0"></script>
 * <div className="gcse-search"></div>
 */
class GoogleResultItem extends React.PureComponent {
	render = () => {
		const { title, link, htmlDesc, image } = this.props.item;
		return (
			<div className="wrap-gcs-item">
				<a className="gcs-item-a" href={link}>
					<div
						dangerouslySetInnerHTML={{
							__html: title,
						}}
					></div>
				</a>
				<p className="gcs-item-link">{link}</p>
				<div className="gcs-item-wrap-desc">
					<a href={link}>
						<img
							className="gcs-item-img"
							src={image?.src}
							width={image?.width}
							height={image?.height}
							alt="nuocmy.net"
						/>
					</a>
					{/* <p className="gcs-item-desc">{description}</p> */}
					<div
						dangerouslySetInnerHTML={{
							__html: htmlDesc,
						}}
					></div>
				</div>
			</div>
		);
	};
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const GoogleSearch = ({ callGoogleCustomSearch, googleObj }) => {
	const query = useQuery();
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		const keywordParam = query.get("q") ?? "";
		const pageParam = query.get("page") ?? 1;
		setKeyword(keywordParam);
		if (keywordParam) callGoogleCustomSearch(keywordParam, pageParam);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeInput = (e) => {
		setKeyword(e.target.value);
	};

	const handlePressKey = (e) => {
		const ENTER_KEYCODE = 13;
		// Check keycode that user press
		if (e.keyCode === ENTER_KEYCODE && keyword) {
			// Call google API
			window.location.href = "/search?q=" + keyword + "&page=1";
			callGoogleCustomSearch(keyword);
		}
	};
	const handlePressSearch = () => {
		if (keyword) {
			window.location.href = "/search?q=" + keyword + "&page=1";
		}
	};

	const onChangePage = (page) => {
		callGoogleCustomSearch(keyword, page);
		window.scrollTo(0, 0);
	};

	return (
		<div className="google-search-container">
			<div className="wrapSearch">
				<input
					value={keyword}
					onKeyDown={handlePressKey}
					onChange={handleChangeInput}
				/>
				<div className="wrap-icon-search" onClick={handlePressSearch}>
					<img
						src={"/images/search.png"}
						className="icon-search"
						alt="icon-search"
					/>
				</div>
			</div>
			<div className="result">
				About {googleObj?.totalResult ?? 0} results (
				{googleObj?.searchTime ?? 0} seconds)
			</div>
			<div className="underline"></div>
			<div>
				{googleObj?.items.map((item, idx) => (
					<GoogleResultItem key={idx} item={item} />
				))}
			</div>
			{googleObj && (
				<div className="wrapPage">
					{[...new Array(Math.ceil(googleObj.totalResult / 10))].map((v, i) => (
						<Link
							className="btnPage"
							key={`google-page-${i + 1}`}
							to={`/search?q=${keyword}&page=${i + 1}`}
							onClick={() => onChangePage(i + 1)}
						>
							{i + 1}
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

export default GoogleSearch;
