import _ from 'lodash';

export const LOADING_THRESHOLD = 500 // ms

export const loadingReducer = (state={request: 0, indicer: 0}, action)=> {
  
  const enabled = action.meta && action.meta.loader

  if (_.endsWith(action.type, '_REQUEST') || _.endsWith(action.type, '_FETCH') || _.endsWith(action.type, '_DOWNLOAD')) {
    return  {
      request: state.request + 1,
      indicer: enabled ? state.indicer + 1 : state.indicer  
    }
  }

  else if (_.endsWith(action.type, '_SUCCESS') || _.endsWith(action.type, '_RECEIVE') || _.endsWith(action.type, '_FAILURE')) {
    return  {
      request: state.request - 1,
      indicer: enabled ? state.indicer - 1 : state.indicer  
    }
  }
  else {
    return state;
  }
}

export const isLoading = (globalState) => {
  const {loadings} = globalState
  return loadings.request > 0;
}

export const isGlobalLoading = (globalState) => {
  const {loadings} = globalState
  return loadings.indicer > 0;
}