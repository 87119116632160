import * as types from '../constant';
export function getData(payload){
  return ({
    type: types.GET_LIST_USA_REQUEST,
    payload
  });
}
export function getCategory(payload){
  return ({
    type: types.GET_CATEGORY_USA_REQUEST,
    payload
  });
}