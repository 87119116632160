import React, { Component } from 'react';
import { convertVietnameseToEnglishString } from '../../utils/common';
// import LazyResponsiveImage from '../common/LazyResponsiveImage';

class NewEvents extends Component {

    render() {
        // Background and text
        let backgroundObj = { src: "", alt: "" };
        let event = { title: "", content: "" };
        if (this.props.images && this.props.images.backgrounds) {
            backgroundObj = this.props.images.backgrounds[1];
        }
        if (this.props.texts.bodyText) {
            event = this.props.texts.bodyText.homepage_event.text
        }

        let events = [];
        if (this.props.imageEvent) {
            events = this.props.imageEvent.events.map((item, key) => {
                // Find first content
                let smallDesc = ""
                const pArr = new DOMParser().parseFromString(item.description, 'text/html').getElementsByTagName("p");
                for (let i = 0; i < pArr.length; i++)
                    smallDesc += pArr[i].textContent ? pArr[i].textContent + " " : ""
                const dt = new Date(item.time)
                const date = dt.getDate().toString().padStart(2, '0')
                const month = dt.getMonth() + 1
                const year = dt.getFullYear()
                return (
                    <div className="col-lg-3 col-md-6 col-sm-12 col-3-event" key={key}>
                        <a
                            style={{ color: "black", textDecoration: "none" }}
                            href={"/event-info/" + convertVietnameseToEnglishString(item.name) + "-" + item._id} >
                            <div className="wrap-event-new">
                                <div className="event-item-img-date">
                                    <div className="event-date">{date}</div>
                                    <div className="event-month">tháng {month}</div>
                                    <div className="event-year">{year}</div>
                                </div>
                                <div className="wrap-img">
                                    <div className="content-xyz">
                                        <img className="img-event" src={item.cover_img} alt={"#event-" + key} />
                                        {/* <LazyResponsiveImage
                                            additionalClass="img-event"
                                            imageId="/images/freedom"
                                            alt="popular-event" /> */}
                                    </div>
                                </div>
                                <div className="wrap-info-event">
                                    <div className="row row-info-event">
                                        <div className="col-12">
                                            <div className="category-event">{item.topic}</div>
                                            <div className="name-event">{item.name}</div>
                                            <div className="location-event">{item.location}</div>
                                            <div className="desc-event-home">
                                                {
                                                    smallDesc
                                                }

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <span className="arrowEvent">
                                            Xem thêm
                                        </span>
                                        <i className="detail-event">→</i>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                )
            })
        }
        return (
            <>
                <div className="new-event">
                    <div className="container-travel text-center wrap-page-intro-block">
                        <span>
                            <h1>{event.title.toUpperCase()}</h1>
                            <p className="m-0 desc-event-main auto-next-line-p">
                                {event.content}
                            </p>
                        </span>
                    </div>
                    <div className="wrap-new-event">
                        <div className="container-travel">
                            <div className="row row-mutiple-item" style={{ justifyContent: "center" }}>
                                {events}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-bgr-img">
                    <a href={backgroundObj.link} className="more-event">
                        <img src={backgroundObj.src} alt={backgroundObj.alt} />
                        <div className="overlay"></div>
                        <div className="wrap-bgr-img-title">
                            <div className="bgr-img-title" >
                                <span>{backgroundObj.title}&nbsp;</span>
                                <i>→</i>
                            </div>
                        </div>
                    </a>
                    <a
                        href="/events"
                        className="bgr-btn btn-more-event btn btn-more-home">
                        <span>
                            Tìm hiểu thêm
                        </span>
                        <i className="icon-arrow-btn">→</i>
                    </a>
                </div>
            </>
        );
    }

}

export default NewEvents;
