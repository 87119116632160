import React from 'react'
import "../styles/event.css"
import ListEventContainer from '../containers/EventPage/ListEventContainer'

const EventPage = (routerObj) => {
  return (
    <div className="EventPage">
      <ListEventContainer routerObj={routerObj}/>
    </div>
  );
}

export default EventPage;