import React, { Component } from 'react';
import LoadingModal from '../../components/common/LoadingModal'
import { connect } from 'react-redux';


class LoadingContainer extends Component {
    render() {
        return (
            this.props.isLoading >0 &&
            <LoadingModal {...this.props} />
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loading.request
    };
};

export default connect(mapStateToProps)(LoadingContainer);