import { put, takeEvery } from 'redux-saga/effects';
import getList from '../../fetchAPI/UsaApi/getList';
import * as types from '../../constant';
function* getData(action) {
  try {
    //page:activePage, cat=cat
    const respone = yield getList(
      action.payload.page, 
      action.payload.topic,
      action.payload.postId || "fakeId",
      action.payload.numberOfPosts || 6
    );
    yield put({
      type: types.GET_LIST_USA_SUCCESS,
      payload: {...respone, page: action.payload.page}
     
    } );
  } catch (error) {
    
    yield put({
      type: types.GET_LIST_USA_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const getListUsa = [
  takeEvery(types.GET_LIST_USA_REQUEST, getData)
];
