import React, { Component } from 'react'
class Map extends Component {
    render() {
        // let mapImg
        // if (this.props.dataTourInfo) {
        //     mapImg = (
        //         <img className="map-info" src={this.props.dataTourInfo.location_img.src}
        //             alt={this.props.dataTourInfo.location_img.alt} />
        //     )
        // }
        return (
            <div>
                {/* {mapImg} */}
            </div>
        )
    }
}
export default Map;
