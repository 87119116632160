import React from 'react'
import GoogleSearchContainer from "../containers/GoogleSearchPage"
import "../styles/google-search-page.css"

export default class GoogleSearchPage extends React.Component {
    render (){
        return(
            <div className="wrap-google-search-page">
               <GoogleSearchContainer />
            </div>
        )
    }
}