import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../actions/UsefulInfoActions'
import Footer from '../../components/common/Footer'
class FooterContainer extends Component {
    componentDidMount() {        
        this.props.getTypeList();
      }
    render() {
        return (
            <Footer {...this.props} />
        );
    }

}
const mapStateToProps = (store) => {
    return {
        dataUsefulInfo: store.useful.usefulInfo,
        dataTypeList: store.listType.listType,
        texts: store.texts.listText
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUsefulInfo: (data) => {
            dispatch(actions.getUseInfo(data))
        },
        getTypeList: (data) => {
            dispatch(actions.getTypeList(data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer)
