import React from 'react'
import "../styles/tour-info.css"
import HeaderContainer from '../containers/TourInfoPage/HeaderContainer'
import MapContainer from '../containers/TourInfoPage/MapContainer'
import ScheduleContainer from '../containers/TourInfoPage/ScheduleContainer'
import ServiceContainer from '../containers/TourInfoPage/ServiceContainer'
import SlideContainer from '../containers/TourInfoPage/SlideContainer'
import MoreContainer from '../containers/TourInfoPage/MoreContainer'
import LoadingContainer from '../containers/common/LoadingContainer'

const TourInfoPage = (routerObj) => {
    return (
      <div className="wrap-tour-info">
        <HeaderContainer routerObj={routerObj}/>
        <div className="wrap-body">
          <div className="container-travel">
            <div className="row">
              <div className="col-md-6">
                <ScheduleContainer />
              </div>
              <div className="col-md-6">
                <MapContainer />
                <SlideContainer />
                <ServiceContainer />
              </div>
            </div>
          </div>
          <MoreContainer />
        </div>
        <LoadingContainer />
      </div >

    );
}

export default TourInfoPage