import * as types from "../constant"

export function AddContact(payload){
    return({
        type: types.ADD_CONTACT_REQUEST,
        payload
    })
};

export function resetAction(){
    return {
        type: types.PREPARE_NEW_CONTACT
    }
}
