import React, { Component } from 'react';
import { connect } from 'react-redux'
// import * as actions from '../../actions/ListTourInfoActions'
import Service from '../../components/tourInfoPage/Service'
class ServiceContainer extends Component { 
    render() {
        return (
            <Service {...this.props} />
        );
    }

}
const mapStateToProps = (state) => {
    return {
      dataTourInfo:state.tourInfo.tourInfo
    };
  };
const mapDispatchToProps = (dispatch) => {
    return {
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(ServiceContainer);

// export default ServiceContainer;
