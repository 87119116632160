import * as types from '../constant';
const DEFAULT_STATE = {
  listTour: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  // activePage: 1,
  totalPage: null
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_TOURLIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        // activePage: action.payload.page
      };
    case types.GET_TOURLIST_SUCCESS:
      // If user get tour of new tab => Reset array
      // Otherwise concat 2 array
      let newListTour = action.payload.page === 1 
        ? action.payload.tours 
        : [...state.listTour, ...action.payload.tours];
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listTour: newListTour,
        totalPage: action.payload.total_pages
      };
    case types.GET_TOURLIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};