import { put, takeEvery } from 'redux-saga/effects';
import getTextNew from '../../fetchAPI/HomePage/NewText';
import * as types from '../../constant';
function* getImageDefault() {
  try {
    const res = yield getTextNew();
    yield put({
      type: types.GET_TEXT_SUCCESS,
      payload: res
    });
  } catch (error) {
    yield put({
      type: types.GET_TEXT_FAILURE,
      payload: {
        errorMessage: error.message
      }
    });
  }
}

export const TextNewSaga = [
  takeEvery(types.GET_TEXT_REQUEST, getImageDefault)
];
