import * as types from '../constant';
export function getListAbout(payload){
  return ({
    type: types.GET_LIST_ABOUT_REQUEST,
    payload
  });
}

export function getIntro(payload){
  return ({
    type: types.GET_INTRO_REQUEST,
    payload
  });
}