import { put, takeEvery } from 'redux-saga/effects';
import * as types from '../constant';
import getLastEvents from '../fetchAPI/common/getLastEvents'
import getLastPosts from '../fetchAPI/common/getLastPosts'

function* getNewEvents() {
    try {
        const res = yield getLastEvents();
        yield put({
            type: types.GET_NEW_EVENTS_HEADER_SUCCESS,
            payload: res.events
        });
    } catch (error) {
        yield put({
            type: types.GET_NEW_EVENTS_HEADER_FAILED,
            payload: {
                errorMessage: error.message
            }
        });
    }
}

function* getNewPosts() {
    try {
        const res = yield getLastPosts();
        yield put({
            type: types.GET_NEW_POSTS_HEADER_SUCCESS,
            payload: res.posts
        });
    } catch (error) {
        yield put({
            type: types.GET_NEW_POSTS_HEADER_FAILURE,
            payload: {
                errorMessage: error.message
            }
        });
    }
}

export const HeaderSaga = [
    takeEvery(types.GET_NEW_EVENTS_HEADER_REQUEST, getNewEvents),
    takeEvery(types.GET_NEW_POSTS_HEADER_REQUEST, getNewPosts)
];