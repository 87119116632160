import {domain}  from '../constant'
export default function callApi(activePage,cat) {
    return new Promise((resolve, reject) => {
      let url = domain + "get-tour-by-type/"+ cat +"/12/"+ activePage
      fetch(url, {
        method: 'GET'
      })
        .then((response) => response.json())
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
    