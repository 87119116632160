import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class ImageSlide extends Component {
    render() {
        let imgs;
        if (this.props.images) {
            if (this.props.images.slides) {
                imgs = this.props.images.slides.reverse().map((item, key) => {
                    return (
                        <div key={key} className="wrap-img-slide-home">
                            <div className="container-group-text-slide">
                                <div className="txt-img-slide text-up-shadow">{item.title}</div>
                                <a
                                    target="nuocmy_sub_frame" rel="noopener noreferrer" href={item.link}
                                    className="btn-more-event btn btn-danger btn-more-home btn-more-img-slide">
                                    {/* btn-contact btn btn-danger btn-more-home */}
                                    <span>Tìm hiểu thêm</span>
                                    <i className="icon-arrow-btn">→</i>
                                </a>
                            </div>
                            <img className="slide_image_home_page"
                                src={item.src}
                                alt={item.alt} />
                        </div>
                    )
                })
            }
        }
        return (
            <Carousel
                showThumbs={false}
                infiniteLoop={true}
                emulateTouch={true}
                autoPlay={true}
            >
                {imgs}
            </Carousel>
        );
    }
}

export default ImageSlide;
