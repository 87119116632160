import React, { Component } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/tourInfoPage/Header'
import * as actions from '../../actions/ListTourInfoActions'
import { getIdFromUrl, changeTabTitle } from '../../utils/common'

class HeaderContainer extends Component {
  state = {}
  componentDidMount() {
    const id = getIdFromUrl()
    this.props.initLoad(id)
  }
  static getDerivedStateFromProps = (nextProps) => {
    if(nextProps.dataTourInfo) {
      changeTabTitle(nextProps.dataTourInfo.name)
    }
    return nextProps
  }
  render() {
    return (
      <Header {...this.props} />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataTourInfo: state.tourInfo.tourInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initLoad: (data) => {
      dispatch(actions.getTourInfo(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);