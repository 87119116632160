import React, { Component } from 'react';
import ImageSlide from '../../components/homePage/ImageSlide';
import * as actions from '../../actions/HomePage';
import { connect } from 'react-redux';
class ImageSlideContainer extends Component {
  componentDidMount() {
    this.props.initLoad();
  }

  render() {
    return (
      <div>
        <ImageSlide {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.imageDault.listImage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initLoad: () => {
      dispatch(actions.getImageDefault());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ImageSlideContainer);