import React from 'react';
import "../styles/usas.css"
import UsasContainer from '../containers/USAsPage/UsasContainer';
class USAsPage extends React.Component {
  render() {
    return (
      <div className="usas" >
        <UsasContainer />
      </div>
    );
  }
}

export default USAsPage;