import React, { Component } from 'react';
import AboutUs from '../../components/aboutUsPage/AboutUs'
import * as actions from '../../actions/About';
import { connect } from 'react-redux';
class AboutUsContainer extends Component {
    componentDidMount() {
        this.props.initLoad()
        this.props.getIntro()
      }   
      render() {
        return (
          <div>
            <AboutUs {...this.props} />
          </div>
        );
      }
    }
    
    const mapStateToProps = (state) => {
      
      return {
        listData: state.listAbout.listData,
        listIntro:state.listIntro.listIntro
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        initLoad: () => {
          dispatch(actions.getListAbout());
        },
        getIntro: () => {
          dispatch(actions.getIntro())
        }
      };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(AboutUsContainer);
