import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { convertVietnameseToEnglishString } from '../../utils/common'
import GoogleCustomSearchInput from './GoogleCustomSearchInput'
library.add(faSearch)

const dropDownTextStyles = { display: "block", fontWeight: "bold", whiteSpace: "nowrap", width: "auto", overflow: "hidden", textOverflow: "ellipsis" }
class Header extends Component {
    render() {
        let tourTypeItems;
        if (this.props.tourTypes) {
            tourTypeItems = this.props.tourTypes.map((item, idx) => {
                return (
                    <a
                        key={idx}
                        style={dropDownTextStyles}
                        className="dropdown-item"
                        href={"/tours/" + item.type_unicode}>
                        {item.type}
                    </a>
                )
            });
        }

        let eventItems
        if (this.props.newEvents) {
            eventItems = this.props.newEvents.map((evt, idx) => {
                const eventInfoUrl = convertVietnameseToEnglishString(evt.name) + "-" + evt._id
                return (
                    <a style={dropDownTextStyles} key={idx} className="dropdown-item" href={"/event-info/" + eventInfoUrl}>{evt.name}</a>
                )
            })
        }

        // let postItems;
        // if(this.props.newPosts) {
        //     postItems = this.props.newPosts.map((post, idx) => {
        //         return (
        //             <a key={idx} className="dropdown-item" href={"/post/" + post._id}>{post.title}</a>
        //         )
        //     });
        // }

        let postItems;
        if (this.props.postTopics) {
            postItems = this.props.postTopics.map((topic, idx) => {
                return (
                    <a
                        style={dropDownTextStyles}
                        key={idx}
                        className="dropdown-item"
                        href={"/usas/" + topic.unicode_name}>
                        {topic.name}
                    </a>
                )
            });
        }
        // let email, 
        let facebook;
        if (this.props.texts.contact) {
            // email = this.props.texts.contact.email;
            facebook = this.props.texts.contact.facebook;
        }

        return (
            <div className="header">
                {/* <div className="wrap-social-info">
                    <div className="container-travel container-header">
                        <div className="row">
                            <div className="col-3 row no-padding"> */}
                {/* Old facebook logo */}
                {/* <a href={facebook} className="a-social" target="nuocmy_sub_frame" rel="noopener noreferrer">
                                    <div className="wrap-btn-social">
                                        <img className="img-social-fb" src={'/images/fb.png'} alt="" />
                                    </div>
                                </a> */}
                {/* <a
                                    href={facebook}
                                    className="a-social"
                                    target="nuocmy_sub_frame" rel="noopener noreferrer">
                                    
                                    <img className="social-img" src={'/images/fb_logo.png'} alt="" />
                                </a>
                            </div>
                            <div className="col-8 row no-padding wrap-col-2-head">
                                <div className="wrap-google-custom-search">
                                    <div
                                        className="gcse-search"
                                        data-resultsurl="http://gotusa.net"
                                        data-newwindow="true"
                                        data-queryparametername="search">
                                    </div>
                                </div>

                            </div>
                            <div className="col-1 email-header">
                                <span className="email-contact">{email}</span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="container-travel wrap-social-info">
                    <div className="wrap-left-header">
                        <a
                            className="a-social"
                            href={facebook}
                            target="nuocmy_sub_frame" rel="noopener noreferrer">
                            <img
                                className="social-img" src={'/images/fb_logo.png'} alt=""
                            />
                        </a>

                    </div>

                    <div className="wrap-right-header">

                        {/* <div className="wrap-google-custom-search">
                            <div
                                className="gcse-search"
                                data-resultsurl="http://nuocmy.net"
                                data-newwindow="true"
                                data-queryparametername="search">
                            </div>
                        </div> */}
                        <GoogleCustomSearchInput {...this.props} />
                        {/* <div className="email-contact">{email}</div> */}
                    </div>
                </div>

                <div className={this.props.isTransparent ? "wrap-transparent-menu" : "wrap-menu"}>
                    <a className="wrap-logo" href="/">
                        <img
                            className="logo-mobile"
                            src={'/images/logo.png'}
                            alt="logo-nuocmy.net"
                        />
                    </a>
                    <div className="container-travel container-menu">
                        <nav className="navbar navbar-expand-lg navbar-dark  bg-light">
                            <div className="wrap-btn-menu">
                                <button className="btnToggle navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item nav-home-mobile">
                            <a className="nav-link nav-title" href="/">
                                TRANG CHỦ
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle nav-title"
                                href="/tours"
                                id="navbarDropdownMenuLink"
                                // data-toggle="dropdown" // Enable change page
                                aria-haspopup="true"
                                aria-expanded="false">
                                LỊCH TRÌNH TOURS
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                {tourTypeItems}
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle nav-title"
                                href="/events"
                                id="navbarDropdownMenuLink1"
                                // data-toggle="dropdown" // Enable change page
                                aria-haspopup="true"
                                aria-expanded="false">
                                SỰ KIỆN MỚI
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                {eventItems}
                            </div>
                        </li>
                        <li className="nav-item logo">
                            <a className="nav-link nav-title" href="/">
                                <img style={{ width: "100%" }} src="/images/logo.png" alt="" />
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle nav-title"
                                href="/usas"
                                id="navbarDropdownMenuLink1"
                                // data-toggle="dropdown" // Enable change page
                                aria-haspopup="true"
                                aria-expanded="false">
                                VẺ ĐẸP MỸ</a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                {postItems}
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link nav-title"
                                href="/price-request"
                                target="nuocmy_sub_frame"
                                onClick={() => localStorage.clear()}>
                                ĐẶT TOURS
                            </a>
                        </li>
                    </ul>
                </div>
                            
                        </nav>
                    </div>
                </div>
                

            </div>
        );
    }

}

export default Header;
