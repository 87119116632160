import React from 'react'
import { 
    Modal, 
    // Header, 
    Image 
} from 'semantic-ui-react'

export default class NotificationModal extends React.Component {

    state = {
        isOpen: false
    }

    open = () => this.setState({ isOpen: true })

    close = () => this.setState({ isOpen: false })

    render = () => {
        return (
            <Modal
                open={this.state.isOpen}
                onClose={this.close}
                size="tiny"
                className="noti-modal"
                centered={false}>
                <Image
                    size="tiny"
                    centered
                    src="./images/tick_icon.png" />
                <Modal.Content >
                    <Modal.Description>
                        {/* <Header textAlign="center">THÀNH CÔNG</Header> */}
                        <p className="noti-modal-text">
                            Cảm ơn bạn đã liên hệ !
                        </p>
                        <p className="noti-modal-text">
                            <b>NuocMy.net</b> đã nhận được yêu cầu của bạn 
                            và sẽ hồi đáp trong thời gian ít hơn 24h tới.
                        </p>
                        {/* <p className="noti-modal-text">
                            Chúng tôi sẽ phản hồi lại trong thời gian sớm nhất
                        </p> */}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}