import * as types from "../../constant";

const DEFAULT_STATE = {
    isAdded: false,
    isSending: false
}
export default (state = DEFAULT_STATE, action) => {

    switch (action.type) {
        case types.ADD_PRICEREQUEST_REQUEST:
            return {
                ...state,
                isSending: true
            }
        case types.ADD_PRICEREQUESR_SUCCESS:
            return {
                ...state,
                isAdded: action.payload.is_success,
                isSending: false
            }
        // Reset value when component receive the action's status
        case types.PREPARE_NEW_PRICEREQUEST:
            return {
                ...state,
                isAdded: false,
                isSending: false
            };
        default:
            return state;
    }
}

