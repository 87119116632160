import * as types from '../constant';
const DEFAULT_STATE = {
  listEvent: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
  activePage: 1,
  totalPage: null
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_EVENTLIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        activePage: action.payload.page
      };
    case types.GET_EVENTLIST_SUCCESS:
      // If user get tour of new tab => Reset array
      // Otherwise concat 2 array
      let newlistEvent = state.activePage === 1 
        ? action.payload.events 
        : [...state.listEvent, ...action.payload.events];
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage: null,
        listEvent: newlistEvent,
        totalPage: action.payload.total_pages
      };
    case types.GET_EVENTLIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};