import * as types from '../../constant';
const DEFAULT_STATE = {
  listImageEvent: null,
  dataFetched: false,
  isFetching: false,
  error: false,
  errorMessage: null,
};


export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case types.GET_IMAGE_EVENT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_IMAGE_EVENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        error: false,
        errorMessage:null,
        listImageEvent: action.payload
      };
    case types.GET_IMAGE_EVENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};