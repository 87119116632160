import React, { Component } from 'react'
import '../../styles/term.css'
class Term extends Component {
    render() {
        let listTerm = []
        if (this.props.terms) {
            listTerm = this.props.terms.map((item, key) => {
                return (
                    <div className="row" key={key}>
                        <div className="">
                            <h4 className="title-term">{item.title}</h4>
                            <div className="content-term">
                                <p className="desc-term-p"
                                    style={{ color: "#777777" }}>{item.content}</p>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div className="wrap-term">
                <div className="image-head-term" style={{
                    // backgroundImage: "url(/images/slide3.jpg)"
                }}>
                    <div className="container-travel wrap-text-head-term wrap-page-intro-block">
                        <h1>ĐIỀU KHOẢN SỬ DỤNG</h1>
                    </div>

                </div>
                {/* <br /> */}
                <div className="container-travel container-terms">
                    {/* <h1 className="title-center">Điều Khoản Sử Dụng</h1> */}
                    <div className="divListTerms">
                        {listTerm}
                    </div>
                </div>
            </div>
        )
    }
}

export default Term