import React from 'react'
import "../styles/highlight.css"
import HighlightContainer from '../containers/HighlightTourPage/HighlightContainer'

class HighlightTourPage extends React.Component {
  render() {
    return (
      <div className="FAQ">
        <HighlightContainer />
      </div>
    );
  }
}

export default HighlightTourPage;