import { put, takeEvery } from "redux-saga/effects";
import * as types from "../constant";
import SearchAPI from "../fetchAPI/3rdPartyAPI/GoogleAPI";
import fakeSearchResult from "./fakeGoogleResult";

function* search(action) {
	try {
		const response = yield SearchAPI(action.payload, action.page);

		let payload = {};
		// Found some matched items => add result to payload
		if (
			response.searchInformation.totalResults &&
			response.searchInformation.totalResults !== "0"
		) {
			payload.totalResult = response.searchInformation.totalResults;
			payload.searchTime = response.searchInformation.formattedSearchTime;
			payload.items = response.items.map((item) => ({
				title: item.htmlTitle,
				link: item.link,
				description: item.snippet,
				htmlDesc: item.htmlSnippet,
				image: item?.pagemap?.cse_thumbnail?.[0],
			}));
		}
		// Not found any item => Add default result to payload
		else {
			payload.totalResult = 0;
			payload.items = [];
			payload.searchTime = response.searchInformation.formattedSearchTime;
		}

		// Dispatch success action
		yield put({
			type: types.CALL_GOOGLE_SEARCH_SUCCESS,
			payload,
		});
	} catch (error) {
		yield put({
			type: types.CALL_GOOGLE_SEARCH_FAILURE,
			payload: {
				errorMessage: error.message,
			},
		});
	}
}

export const GoogleSaga = [takeEvery(types.CALL_GOOGLE_SEARCH_REQUEST, search)];
