import React, { Component } from 'react';
import NewEvents from '../../components/homePage/NewEvents'
import * as actionss from '../../actions/HomePage';
import { connect } from 'react-redux';
class NewEventsContainer extends Component {
  componentDidMount() {
    this.props.initLoad();
  }

    render() {
        return (
            <NewEvents {...this.props}/>
        );
    }

}
const mapStateToProps = (state) => {
    return {
      images: state.imageDault.listImage,
      texts : state.texts.listText,
      imageEvent: state.imageEvent.listImageEvent
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      initLoad: () => {
        dispatch(actionss.getImageEvent());
      }
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(NewEventsContainer);

