import * as types from "../../constant";
import { put, takeEvery } from 'redux-saga/effects'
import AddContactAPI from '../../fetchAPI/ContactPage/ContactAPI'

function* AddContact(action) {
    try {
        const response = yield AddContactAPI(action.payload);
        yield put({
            type: types.ADD_CONTACT_SUCCESS,
            payload: response
        })
    } catch (error) {
        yield put({
            type: types.ADD_CONTACT_FAILURE,
            payload: {
                errorMessage: error.message
            }
        })
    }
}
export const ContactSaga = [
    takeEvery(types.ADD_CONTACT_REQUEST, AddContact)
];


