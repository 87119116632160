import React, { Component } from 'react';
import FAQ from '../../components/faqPage/FAQ'
import * as actions from '../../actions/FAQ';
import { connect } from 'react-redux';
class FAQContainer extends Component {
    componentDidMount() {
        this.props.initLoad();
      }
    
      render() {
        return (
          <div>
            <FAQ {...this.props} />
          </div>
        );
      }
    }
    
    const mapStateToProps = (state) => {
      return {
        listData: state.list.listText,
        texts: state.texts.listText,
      };
    };
    
    const mapDispatchToProps = (dispatch) => {
      return {
        initLoad: () => {
          dispatch(actions.getList())
        }
      };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(FAQContainer);
